import React from "react";
// import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, ProgressBar } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";

import { GlobalSetting, LayoutScreen, PermissionAccessType } from "../../utilities/GlobalSetting";
import { CheckBoolean, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, CheckValueNA, DecapitalizeJsonKeys, Delay, GetPostParams, GetPropIds, PermissionAccess, UTCtoLocalTime } from "../../utilities/GlobalFunctions";
import { useGlobal } from "../../utilities/GlobalVariables";
import { useAppService } from "../../services/AppService";
import { AlertMode } from "../AlertComponent";
import { Locale } from "../../utilities/localization/CustomLocalization";

const SettingInput = {
    None: 'none',
    Id: 'id',
    ClassroomId: 'classroomId',
    Name: 'name',
    Classroom: 'classroom',
    GroupName: 'groupName',
    GroupId: 'groupId',
    GroupOption: 'groupOption',
    Remark: 'remark',
    DisplayOrder: 'displayOrder',
    Active: 'active',
    EffectiveDateStart: 'effectiveDateStart',
    EffectiveDateEnd: 'effectiveDateEnd',
    IsExist: 'isExist',
    AllowCreateOrUpdate: 'allowCreateOrUpdate',
    CheckedItem: 'checkedItem',
};

const BulkSetting = {
    GroupId: 'groupId',
    DisplayOrder: 'displayOrder',
    Active: 'active',
    EffectiveDateStart: 'effectiveDateStart',
    EffectiveDateEnd: 'effectiveDateEnd',
    Remark: 'remark',
};

const settingTitle = 'Classroom';
const yearStart = moment(moment.utc().format('YYYY-01-01T00:00')).add(-1, 'day').format('YYYY-MM-DDTHH:mm');
const yearEnd = moment(yearStart).add(1, 'year').add(-1, 'day').format('YYYY-MM-DDTHH:mm');

//2024.09.28
export default class SettingClassroom extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',
        BulkEdit_CheckedItems: [],

        Toggle_EditSettingModal: false,
        SettingModal_Create: false,
        SettingModal_Index: -1,
        SettingModal: null,
        SettingModal_Cache: null,
        isUpdating: false,

        Toggle_RemoveSettingModal: false,

        //2024.10.21
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,

        //2024.11.18
        ScannedResultModal_Toggle: false,
        ScannedResultItems: [],
        ScannedResultItems_cache: [],
        ScannedResultItems_backup: [],
        ScannedResultItems_checked: [],
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await useAppService.getState().getGroups();
        await Delay(0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === list ===
    CheckPermissions = async () => {
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageClassroom, PermissionAccessType.Download),

            // PageSize: CheckNumber(localStorage.getItem(`ManageSetting_CustomGroup_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            PageSize: 999,
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            gv: useGlobal.getState(),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (active = true) => {

        await this.CheckPermissions();    //2024.07.24

        // if (this.state.PA_View === false)
        //     return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Classroom/List/${organizerId}/${authorId}`;
        //  Api/LearningCentre/Organizer/Classroom/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data)) {
                            _List = data.data;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log(`${settingTitle} list is empty.`);
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - setting - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - setting - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            List: JSON.parse(JSON.stringify(_List)),
            TotalRows: totalRows,
            IsListLoaded: true,
            BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        const checkedItems = this.state.BulkEdit_CheckedItems;
        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key} className={`${checkedItems[key] ? 'setting-classroom-tr-class' : ''}`}>
                <td className="pointer" onClick={() => this.ToggleItemChecked(key)}><input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input></td>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{CheckValueNA(data[SettingInput.Name])}</td>
                <td>{CheckObjectStringEmpty(data, SettingInput.GroupName)}</td>
                <td>{CheckObjectNumber(data, SettingInput.DisplayOrder)}</td>
                <td>{CheckObjectBoolean(data, SettingInput.Active) ? '✔' : '❌'}</td>
                <td>{UTCtoLocalTime(CheckObjectStringEmpty(data, SettingInput.EffectiveDateStart))}</td>
                <td>{UTCtoLocalTime(CheckObjectStringEmpty(data, SettingInput.EffectiveDateEnd))}</td>
                <td>{
                    CheckObjectNullValue(data, SettingInput.Remark) === null ? '-' :
                        <Button
                            variant='info'
                            onClick={() => useAppService.getState().setModal(`Remark :: ${CheckValueNA(data[SettingInput.Name])}`, CheckValueNA(data[SettingInput.Remark]))}
                        >View</Button>
                }</td>
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditSettingModal(key)}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    //#endregion

    //#region === edit / new ===
    ToggleEditSettingModal = async (index = -1, create = false) => {
        // useAppService.getState().setModal('', 'coming soon...');
        if (create) {
            if (this.state.PA_Create === false) {
                useAppService.getState().setModal(`New ${settingTitle}`, 'Invalid permission.');
                return null;
            }
            else {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                const defaultModal = this.PopulateSettingModal(null, organizerId, authorId);
                this.setState({
                    // Toggle_EditSettingModal: true,
                    SettingModal_Create: true,
                    SettingModal_Index: -1,
                    SettingModal: defaultModal,
                    SettingModal_Cache: JSON.parse(JSON.stringify(defaultModal)),
                    isUpdating: false,
                });
                await Delay(200);
                this.setState({
                    Toggle_EditSettingModal: true,
                });
                return null;
            }
        }
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Edit ${settingTitle}`, 'Invalid permission.');
            return null;
        }
        if (index < 0) {
            this.setState({ Toggle_EditSettingModal: false });
            await Delay(200);
        }
        this.setState({
            SettingModal_Create: false,
            SettingModal_Index: index,
            SettingModal: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            SettingModal_Cache: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            isUpdating: false,
        });
        if (index >= 0) {
            await Delay(200);
            this.setState({ Toggle_EditSettingModal: true });
        }
        if (this.state.isDevMode)
            console.log(`ToggleEditSettingModal (${index}) (${create}) =\n ${JSON.stringify(this.state.SettingModal)}`);
    }
    SettingModalComponent = () => {
        let components = [];
        const settingDetails = this.state.SettingModal;
        if (settingDetails !== null) {

            const { authorId, } = settingDetails;
            const readOnly = this.state.isSuperAdmin ? false : authorId === 1;

            //Name.
            components.push(<div className="form-group" key='s-e-name'>
                <label>Name</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Name, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.Name)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Group.
            const groupOptions = useAppService.getState().groupOptions ?? [];
            const findIndex_group = Array.isArray(groupOptions) && groupOptions.length > 0 ? groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(settingDetails, SettingInput.GroupId, 17)) : -1;
            components.push(<div className="form-group" key='s-e-group'>
                <label>Group</label><br />
                <DropdownButton
                    as={ButtonGroup}
                    title={findIndex_group < 0 ? (groupOptions.length > 0 ? groupOptions.find(x => x.id === 17).label : '') : groupOptions[findIndex_group].label}
                    onSelect={(eventKey) => { if (!readOnly) this.SetSettingValue(SettingInput.GroupId, eventKey); }}
                >{
                        Array.isArray(groupOptions) && groupOptions.length > 0 ?
                            groupOptions.map((option, key) => {
                                return <Dropdown.Item eventKey={option.id}>{option.label}</Dropdown.Item>;
                            })
                            : null
                    }</DropdownButton>
            </div>);

            //Remark.
            components.push(<div className="form-group" key='s-e-remark'>
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Remark, e.target.value); }}
                    defaultValue={CheckObjectStringEmpty(settingDetails, SettingInput.Remark)}
                    disabled={readOnly}
                ></input>
            </div>);

            //DisplayOrder.
            components.push(<div className="form-group" key='s-e-display-order'>
                <label>DisplayOrder</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.DisplayOrder, e.target.value); }}
                    defaultValue={CheckObjectNumber(settingDetails, SettingInput.DisplayOrder)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Active.
            components.push(<div className="form-group" key='s-e-active'>
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Active, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.Active)}
                    disabled={readOnly} readOnly={true}
                ></input>
            </div>);

            //Effective Date Start.
            const effectiveDateStart = CheckObjectStringEmpty(settingDetails, SettingInput.EffectiveDateStart, yearStart);
            components.push(<div className="form-group" key='s-e-effectiveDateStart'>
                <label>Effective Date Start</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.EffectiveDateStart, e.target.value); }}
                    value={moment.utc(effectiveDateStart).local().format('YYYY-MM-DDTHH:mm')}
                    disabled={readOnly}
                ></input>
            </div>);

            //Effective Date End.
            const effectiveDateEnd = CheckObjectStringEmpty(settingDetails, SettingInput.EffectiveDateEnd, yearEnd);
            components.push(<div className="form-group" key='s-e-effectiveDateEnd'>
                <label>Effective Date End</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.EffectiveDateEnd, e.target.value); }}
                    value={moment.utc(effectiveDateEnd).local().format('YYYY-MM-DDTHH:mm')}
                    disabled={readOnly}
                ></input>
            </div>);

        }
        return (components);
    }
    SetSettingValue = (property = SettingInput.None, value = null) => {
        let setting = this.state.SettingModal;
        if (setting === null || value === null)
            return null;
        switch (property) {
            case SettingInput.Name: setting[property] = String(value); break;
            case SettingInput.Remark: setting[property] = String(value); break;
            case SettingInput.GroupId:
                let groupName = '';
                const groupId = CheckNumber(value);
                if (groupId > 0) {
                    const groupOptions = useAppService.getState().groupOptions;
                    if (Array.isArray(groupOptions)) {
                        const findIndex = groupOptions.findIndex(x => Number(x.id) === groupId);
                        if (findIndex > -1)
                            groupName = String(groupOptions[findIndex].label);
                    }
                }
                setting[property] = groupId;
                setting[SettingInput.GroupName] = groupName;
                break;
            case SettingInput.DisplayOrder: setting[property] = CheckNumber(value); break;
            case SettingInput.Active: setting[property] = CheckBoolean(value); break;
            case SettingInput.EffectiveDateStart: setting[property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            case SettingInput.EffectiveDateEnd: setting[property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            default: break;
        }
        this.setState({
            SettingModal: setting,
        }, () => {
            if (this.state.isDevMode)
                console.log(`SetSettingValue (${property})`, JSON.stringify(setting));
        });
    }
    ResetSetting = () => {
        this.setState({
            SettingModal: JSON.parse(JSON.stringify(this.state.SettingModal_Cache)),
        });
    }
    PopulateSettingModal = (modal = null, organizerId = 0, authorId = 0) => {
        return {
            id: CheckObjectNumber(modal, SettingInput.Id),
            classroomId: CheckObjectNumber(modal, SettingInput.ClassroomId),

            organizerId: CheckObjectNumber(modal, 'organizerId', organizerId),
            authorId: CheckObjectNumber(modal, 'authorId', authorId),

            name: CheckObjectStringEmpty(modal, SettingInput.Name),
            classroom: CheckObjectStringEmpty(modal, SettingInput.Classroom),
            remark: CheckObjectStringEmpty(modal, SettingInput.Remark),

            groupId: CheckObjectNumber(modal, SettingInput.GroupId),
            groupName: CheckObjectStringEmpty(modal, SettingInput.GroupName),

            displayOrder: CheckObjectNumber(modal, SettingInput.DisplayOrder),
            active: CheckObjectBoolean(modal, SettingInput.Active),

            effectiveDateStart: CheckObjectStringEmpty(modal, SettingInput.EffectiveDateStart),
            effectiveDateEnd: CheckObjectStringEmpty(modal, SettingInput.EffectiveDateEnd),

            isExist: CheckObjectBoolean(modal, SettingInput.IsExist),
            allowCreateOrUpdate: CheckObjectBoolean(modal, SettingInput.AllowCreateOrUpdate),
        };
    }
    SettingModalValidation = (postData = null) => {
        if (postData === null)
            return { success: false, message: 'invalid modal.' };

        let messages = [];

        if (CheckObjectNullValue(postData, 'name') === null)
            messages.push('Name must not be empty.');

        if (CheckObjectNullValue(postData, 'groupId') === null || CheckObjectNullValue(postData, 'groupName') === null)
            messages.push('Group must be selected.');

        return { success: messages.length === 0, message: messages.join('<br />') };
    }
    CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const postData = this.PopulateSettingModal(this.state.SettingModal);

        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        const { textTitle, textBody, text, urlParam } = GetPostParams(postData, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Classroom/${urlParam}`;
        // Api/LearningCentre/Organizer/Classroom/{Create|Update|Remove}

        const { id: _classroomId, name, remark, groupId, groupName, displayOrder, active, effectiveDateStart, effectiveDateEnd } = postData;
        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            ClassroomId: CheckNumber(_classroomId),
            Name: CheckStringEmpty(name),
            Remark: CheckStringEmpty(remark),

            GroupId: CheckNumber(groupId),
            GroupName: CheckStringEmpty(groupName),

            DisplayOrder: CheckNumber(displayOrder),
            Active: CheckBoolean(active),

            EffectiveDateStart: CheckStringEmpty(effectiveDateStart),
            EffectiveDateEnd: CheckStringEmpty(effectiveDateEnd),

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            if (remove)
                this.ToggleRemoveSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} setting.<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion

    //#region === remove ===
    ToggleRemoveSettingModal = (modal = null) => {
        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }
        this.setState({
            Toggle_RemoveSettingModal: modal === null ? false : !this.state.Toggle_RemoveSettingModal,
            isUpdating: false,
        });
    }
    //#endregion

    //#region === bulk edit ===
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Group.
        const group_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.GroupId);
        const group_setting_checked = setting_checked[group_setting_index];
        const groupOptions = useAppService.getState().groupOptions;
        const groupValueIndex = groupOptions === undefined ? -1 : groupOptions.findIndex(x => Number(x.id) === CheckNumber(setting[group_setting_index].value));
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (group_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max">
                <label>Group</label>
                <Select
                    options={groupOptions}
                    placeholder={groupValueIndex < 0 ? Locale("not-specify-group", this.state.locale) : groupOptions[groupValueIndex].label}
                    value={groupValueIndex < 0 ? 0 : groupOptions[groupValueIndex].value}
                    onChange={(option) => this.BulkEdit_SetSetting(SettingInput.GroupId, option)}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, group_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : group_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Display Order.
        const displayOrder_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.DisplayOrder);
        const displayOrder_setting_checked = setting_checked[displayOrder_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (displayOrder_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Display Order</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    value={CheckNumber(setting[displayOrder_setting_index].value)}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.DisplayOrder, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, displayOrder_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : displayOrder_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Active.
        const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        const active_setting_checked = setting_checked[active_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.Active, e.currentTarget.checked)}
                    checked={CheckBoolean(setting[active_setting_index].value)}
                    readOnly={true}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, active_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date Start.
        const effectiveDateStart_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateStart);
        const effectiveDateStart_setting_checked = setting_checked[effectiveDateStart_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateStart_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date Start</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateStart, e.target.value)}
                    value={moment.utc(setting[effectiveDateStart_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateStart_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateStart_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date End.
        const effectiveDateEnd_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateEnd);
        const effectiveDateEnd_setting_checked = setting_checked[effectiveDateEnd_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateEnd_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date End</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateEnd, e.target.value)}
                    value={moment.utc(setting[effectiveDateEnd_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateEnd_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateEnd_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Remark.
        const remark_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Remark);
        const remark_setting_checked = setting_checked[remark_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    value={CheckStringEmpty(setting[remark_setting_index].value)}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.Remark, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, remark_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === SettingInput.CheckedItem ? 999 : Object.values(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index) = ` + JSON.stringify(setting_index));
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case SettingInput.GroupId: setting[setting_index].value = CheckObjectNumber(value, 'id'); break;
            case SettingInput.DisplayOrder: setting[setting_index].value = CheckNumber(value); break;
            case SettingInput.Active: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.EffectiveDateStart: setting[setting_index].value = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            case SettingInput.EffectiveDateEnd: setting[setting_index].value = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            case SettingInput.Remark: setting[setting_index].value = String(value); break;
            case SettingInput.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        const currentUtc = moment.utc();
        this.setState({
            BulkEdit_Setting: [
                { key: BulkSetting.GroupId, value: 0 },
                { key: BulkSetting.DisplayOrder, value: 0 },
                { key: BulkSetting.Active, value: false },
                { key: BulkSetting.EffectiveDateStart, value: currentUtc.format('YYYY-MM-DD HH:mm') },
                { key: BulkSetting.EffectiveDateEnd, value: currentUtc.add(6, 'months').format('YYYY-MM-DD HH:mm') },
                { key: BulkSetting.Remark, value: '' },
            ],
            // ] : Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Classroom/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Classroom/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let classroomIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                classroomIds.push(CheckObjectNumber(list[n], 'id'));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkClassroomIds: classroomIds.join(','),
            Remark: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Remark)].value,
            GroupId: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.GroupId)].value,
            DisplayOrder: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.DisplayOrder)].value,
            Active: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Active)].value,
            EffectiveDateStart: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateStart)].value,
            EffectiveDateEnd: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateEnd)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi (response) =\n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');
                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `All selected ${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} selected classroom(s).<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    //#region === scan & insert ===
    ScanExistingItemsViaApi = async () => {
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            isLoading: true,
        });
        useAppService.getState().setModal('', 'Scanning classrooms...', null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Classroom/Scan/${organizerId}/${authorId}`;
        // Api/LearningCentre/Organizer/Classroom/Scan/{organizerId}/{authorId}

        let list = [];
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('ScanExistingItemsViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                list = DecapitalizeJsonKeys(data.data);

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - scan items (error)\n' + error.message);
            });

        if (Array.isArray(list) === false || list === null || list === undefined)
            list = [];

        if (success) {
            this.setState({
                ScannedResultItems: list,
                ScannedResultItems_cache: JSON.parse(JSON.stringify(list)),
                ScannedResultItems_checked: list.map((data, key) => { return false; }),
                ScannedResultItems_backup: JSON.parse(JSON.stringify(list)),
            });
            await Delay(500);
            useAppService.getState().setModal();
            this.ToggleScannedResultModal();
        }
        else {
            useAppService.getState().setModal('', 'Failed to find classroom(s).<br /><br />' + msg);
        }
        this.setState({
            isLoading: false,
        });
    }
    ToggleScannedResultModal = () => {
        const toggle = !this.state.ScannedResultModal_Toggle;
        this.setState({
            ScannedResultModal_Toggle: toggle,
        }, async () => {
            if (toggle === false) {
                await Delay(500);
                this.setState({
                    ScannedResultItems: [],
                    ScannedResultItems_cache: [],
                    ScannedResultItems_backup: [],
                    ScannedResultItems_checked: [],
                });
            }
        });
    }
    ScannedResultModalComponent = () => {
        let components = [];

        const readOnly = this.state.PA_Update === false;
        const list = this.state.ScannedResultItems;
        const list_checked = this.state.ScannedResultItems_checked;
        const list_cache = this.state.ScannedResultItems_cache;
        if (Array.isArray(list) === false || list.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        const groupOptions = useAppService.getState().groupOptions;

        list.map((data, key) => {
            const effectiveDateStart = CheckObjectStringEmpty(data, SettingInput.EffectiveDateStart, yearStart);
            const effectiveDateEnd = CheckObjectStringEmpty(data, SettingInput.EffectiveDateEnd, yearEnd);
            return components.push(<tr className={' ' + (list_checked[key] ? 'td-highlighted' : '')}>
                {/* # */}
                <td>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                        <span>{key + 1}</span>
                        <i className="fa fa-times-circle red pointer" title="Click to remove this item"
                            onClick={() => {
                                let items = this.state.ScannedResultItems;
                                if (items.length > 0)
                                    items.splice(key, 1);
                                let items_cache = this.state.ScannedResultItems_cache;
                                if (items_cache.length > 0)
                                    items_cache.splice(key, 1);
                                let items_checked = this.state.ScannedResultItems_checked;
                                if (items_checked.length > 0)
                                    items_checked.splice(key, 1);
                                this.setState({
                                    ScannedResultItems: items,
                                    ScannedResultItems_cache: items_cache,
                                    ScannedResultItems_checked: items_checked,
                                });
                            }}></i>
                    </div>
                </td>
                {/* Classroom */}
                <td>
                    <div className="egr-style icon-color">
                        <input type="text" className="form-control"
                            key={`sc-i-name-${key}`}
                            onChange={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.Classroom, e.target.value); }}
                            value={String(data[SettingInput.Classroom])}
                            disabled={readOnly}
                            style={data[SettingInput.Classroom] === list_cache[key][SettingInput.Classroom] ? { width: '100%' } : { width: '100%', fontWeight: 'bold' }}
                        ></input>
                        {
                            CheckObjectBoolean(data, SettingInput.IsExist) ?
                                <i className="fa fa-check-circle blue pointer" title="Classroom already existed."></i>
                                : <i className="fa fa-exclamation-circle orange pointer" title="Classroom not exist."></i>
                        }
                    </div>
                </td>
                {/* Group */}
                <td className="group-dropdown">
                    <DropdownButton
                        as={ButtonGroup}
                        key={`sc-i-group-${key}`}
                        variant={data[SettingInput.GroupId] === list_cache[key][SettingInput.GroupId] ? 'secondary' : 'info'}
                        title={Array.isArray(groupOptions) && groupOptions.length > 0 ? groupOptions.find(x => Number(x.id) === CheckObjectNumber(data, SettingInput.GroupId, 17)).label : ''}
                        onSelect={(eventKey) => this.SetScannedItemValue(key, SettingInput.GroupOption, eventKey - 1)}
                    >
                        {
                            Array.isArray(groupOptions) && groupOptions.length > 0 ?
                                groupOptions.map((option, key) => {
                                    return <Dropdown.Item eventKey={key + 1}>{option.label}</Dropdown.Item>;
                                })
                                : null
                        }</DropdownButton>
                </td>
                {/* EffectiveDateStart */}
                <td>
                    <input type="datetime-local" className="form-control"
                        key={`sc-i-effectiveDateStart-${key}`}
                        onChange={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.EffectiveDateStart, e.target.value); }}
                        value={moment.utc(effectiveDateStart).local().format('YYYY-MM-DDTHH:mm')}
                        disabled={readOnly}
                        style={data[SettingInput.EffectiveDateStart] === list_cache[key][SettingInput.EffectiveDateStart] ? { width: '100%' } : { width: '100%', fontWeight: 'bold' }}
                    ></input>
                </td>
                {/* EffectiveDateEnd */}
                <td>
                    <input type="datetime-local" className="form-control"
                        key={`sc-i-effectiveDateEnd-${key}`}
                        onChange={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.EffectiveDateEnd, e.target.value); }}
                        value={moment.utc(effectiveDateEnd).local().format('YYYY-MM-DDTHH:mm')}
                        disabled={readOnly}
                        style={data[SettingInput.EffectiveDateEnd] === list_cache[key][SettingInput.EffectiveDateEnd] ? { width: '100%' } : { width: '100%', fontWeight: 'bold' }}
                    ></input>
                </td>
                {/* DisplayOrder */}
                <td>
                    <input type="number" className="form-control"
                        key={`sc-i-displayOrder-${key}`}
                        onChange={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.DisplayOrder, e.target.value); }}
                        value={CheckObjectNumber(data, SettingInput.DisplayOrder)}
                        disabled={readOnly}
                        style={data[SettingInput.DisplayOrder] === list_cache[key][SettingInput.DisplayOrder] ? { width: '100%' } : { width: '100%', fontWeight: 'bold' }}
                    ></input>
                </td>
                {/* Active */}
                <td>
                    <div className="egr-style">
                        <input type="checkbox" className="form-check form-check-input"
                            key={`sc-i-active-${key}`}
                            onClick={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.Active, e.currentTarget.checked); }}
                            checked={CheckObjectBoolean(data, SettingInput.Active)}
                            disabled={readOnly} readOnly={true}
                        ></input>
                    </div>
                </td>
                {/* Select */}
                <td>
                    <div className="egr-style">
                        <input type="checkbox" className="form-check form-check-input"
                            key={`sc-i-checked-${key}`}
                            onClick={(e) => { if (!readOnly) this.SetScannedItemValue(key, SettingInput.CheckedItem, e.currentTarget.checked); }}
                            checked={list_checked[key]}
                            disabled={readOnly} readOnly={true}
                        ></input>
                    </div>
                </td>
            </tr>)
        });

        return (components);
    }
    SetScannedItemValue = (index = -1, property = SettingInput.None, value = null) => {
        if (index < 0 || property === SettingInput.None)
            return null;
        let items = this.state.ScannedResultItems;
        if (Array.isArray(items) === false || items.length === 0)
            return null;
        let items_checked = this.state.ScannedResultItems_checked;
        if (Array.isArray(items_checked) === false || items_checked.length === 0)
            return null;

        switch (property) {
            case SettingInput.Classroom: items[index][property] = String(value); break;
            case SettingInput.GroupOption:
                const groupOptions = useAppService.getState().groupOptions;
                const group_index = Number(value);
                if (group_index > -1) {
                    items[index][SettingInput.GroupId] = Number(groupOptions[group_index].id)
                    items[index][SettingInput.GroupName] = String(groupOptions[group_index].value)
                }
                break;
            case SettingInput.Active: items[index][property] = CheckBoolean(value); break;
            case SettingInput.EffectiveDateStart: items[index][property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            case SettingInput.EffectiveDateEnd: items[index][property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            case SettingInput.Remark: items[index][property] = String(value); break;
            case SettingInput.DisplayOrder: items[index][property] = CheckNumber(value); break;
            case SettingInput.CheckedItem:
                items_checked[index] = !items_checked[index];
                if (this.state.isDevMode)
                    console.log(`SetScannedItemValue (checkedItem) (${index}) = ${items_checked[index]}`);
                break;
            default: break;
        }
        this.setState({
            ScannedResultItems: items,
            ScannedResultItems_checked: items_checked,
        }, () => {
            if (this.state.isDevMode) {
                console.log(`SetScannedItemValue (${index}) (${property}) = ` + JSON.stringify(value));
                console.log(`SetScannedItemValue (setting) = ` + JSON.stringify(items));
            }
        });
    }
    ScannedResult_ResetValues = () => {
        this.setState({
            ScannedResultItems: JSON.parse(JSON.stringify(this.state.ScannedResultItems_backup)),
            ScannedResultItems_cache: JSON.parse(JSON.stringify(this.state.ScannedResultItems_backup)),
            ScannedResultItems_checked: this.state.ScannedResultItems_backup.map((data, key) => { return false; }),
        });
    }
    ScannedResult_CreateOrUpdate_Items_ViaApi = async () => {

        if (this.state.PA_Update === false)
            return null;

        const list = this.state.ScannedResultItems;
        const list_checked = this.state.ScannedResultItems_checked;

        if (Array.isArray(list) === false || Array.isArray(list_checked) === false)
            return null;

        if (list_checked.indexOf(true) > -1) { }
        else {
            useAppService.getState().setModal('', 'No item is selected.<br />Please select at least one or more items.');
            return null;
        }

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            isLoading: true,
            isUpdating: true,
        });
        useAppService.getState().setModal('', 'Processing classrooms...', null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Classroom/Scan/CreateOrUpdate`;
        // Api/LearningCentre/Organizer/Classroom/Scan/CreateOrUpdate

        let classrooms = [];
        list.map((data, key) => {
            if (list_checked[key]) {
                let item = this.PopulateSettingModal(data, organizerId, authorId);
                item[SettingInput.AllowCreateOrUpdate] = list_checked[key];
                classrooms.push(item);
            }
            return null;
        });

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            Classrooms: classrooms,
        });

        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('ScannedResult_CreateOrUpdate_Items_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - create/update items (error)\n' + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            await Delay(500);
            this.ToggleScannedResultModal();
            useAppService.getState().setModal();
            await Delay(1000);
            useAppService.getState().setModal('', `Classroom(s) have been added/updated.`);
        }
        else {
            useAppService.getState().setModal('', 'Failed to create/update classroom(s).<br /><br />' + msg);
        }
        this.setState({
            isLoading: false,
            isUpdating: false,
        });
    }
    //#endregion

    render = () => {
        return (<>
            <table className="table" style={{ tableLayout: 'fixed', marginBottom: 0 }}>
                <tbody>
                    <tr>
                        <td className="settingTitle">
                            <h5>Manage {settingTitle}</h5>
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                hidden={!this.state.PA_Update}
                            >Bulk Edit</Button>
                        </td>
                        <td align="right">
                            <div className="page-ur-btn-div">
                                <Button variant="primary"
                                    onClick={() => this.ScanExistingItemsViaApi()}
                                    disabled={this.state.isLoading || this.state.isUpdating || !this.state.IsListLoaded}
                                    hidden={!this.state.PA_Create}
                                >Scan Classroom</Button>
                                <Button variant="primary" onClick={() => this.ToggleEditSettingModal(-1, true)}>Add New</Button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className='pointer' readOnly={true}
                                checked={this.state.BulkEdit_CheckedItems.length > 0 ? this.state.BulkEdit_CheckedItems.indexOf(false) < 0 : false}></input>
                        </th>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        <th width='115'>Group</th>
                        <th width='115'>Display Order</th>
                        <th width='115'>Active</th>
                        <th width='115'>Effective<br />Start Date</th>
                        <th width='115'>Effective<br />End Date</th>
                        <th width='125'>Remark</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Setting - Edit / Update - Modal */}
            <Modal show={this.state.Toggle_EditSettingModal}
                onHide={() => this.ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.SettingModal_Create ? `New ${settingTitle}` : 'Edit :: '} {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.ToggleRemoveSettingModal(this.state.SettingModal)}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false || CheckObjectNumber(this.state.SettingModal, 'authorId') === 1)}
                    >Remove</Button>
                    <Button variant="secondary" onClick={() => this.ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.SettingModal_Create ?
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Setting - Remove (Confirm) - Modal */}
            <Modal show={this.state.Toggle_RemoveSettingModal}
                onHide={() => this.ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this {settingTitle.toLowerCase()} ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, {settingTitle.toLowerCase()} will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        // onClick={() => this.RemoveSetting_ViaApi()}
                        onClick={() => this.CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.isUpdating}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Remove (Confirm) - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_RemoveSettingModal}
                onHide={() => this.BulkEdit_ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove these items ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, they will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.BulkEdit_CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Scanned Result - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.ScannedResultModal_Toggle}
                onHide={() => this.ToggleScannedResultModal()}
                centered size="xl"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Classroom(s) found</Modal.Title>
                </Modal.Header>
                <Modal.Body className="scanned-result-bulk-parent">
                    <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                        <thead>
                            <tr>
                                <th width='50'>#</th>
                                <th className="left">Classroom</th>
                                <th width='115'>Group</th>
                                <th width='115'>
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                        <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                                            key={`sc-i-effectiveDateStart-parent`}
                                            onChange={(e) => {
                                                let items = this.state.ScannedResultItems;
                                                if (items.length > 0) {
                                                    const value = e.target.value;
                                                    for (let i = 0; i < items.length; i++) {
                                                        items[i][SettingInput.EffectiveDateStart] = moment(value).utc().format('YYYY-MM-DD HH:mm');
                                                    }
                                                    this.setState({ ScannedResultItems: items, });
                                                }
                                            }}
                                            disabled={this.state.PA_Update === false}
                                            title="Edit start date & time of all items"
                                        ></input>
                                        <span>Effective Start Date</span>
                                    </div>
                                </th>
                                <th width='115'>
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                        <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                                            key={`sc-i-effectiveDateStart-parent`}
                                            onChange={(e) => {
                                                let items = this.state.ScannedResultItems;
                                                if (items.length > 0) {
                                                    const value = e.target.value;
                                                    for (let i = 0; i < items.length; i++) {
                                                        items[i][SettingInput.EffectiveDateEnd] = moment(value).utc().format('YYYY-MM-DD HH:mm');
                                                    }
                                                    this.setState({ ScannedResultItems: items, });
                                                }
                                            }}
                                            disabled={this.state.PA_Update === false}
                                            title="Edit end date & time of all items"
                                        ></input>
                                        <span>Effective End Date</span>
                                    </div>
                                </th>
                                <th width='75'>Display<br />Order</th>
                                <th width='55' onClick={() => {
                                    let items = this.state.ScannedResultItems;
                                    if (items.length > 0) {
                                        const checked_none = items.findIndex(x => x[SettingInput.Active] === false) < 0;
                                        for (let i = 0; i < items.length; i++) {
                                            items[i][SettingInput.Active] = !checked_none;
                                        }
                                        this.setState({ ScannedResultItems: items, });
                                    }
                                }} className="pointer" title="Select All">
                                    <input type='checkbox' className='form-check-input' readOnly={true}
                                        checked={this.state.ScannedResultItems.length > 0 ? this.state.ScannedResultItems.findIndex(x => x[SettingInput.Active] === false) < 0 : false}></input>
                                    <br />Active
                                </th>
                                <th width='55' onClick={() => {
                                    const checked = this.state.ScannedResultItems_checked;
                                    if (checked.length > 0) {
                                        const checked_none = checked.indexOf(false) < 0;
                                        this.setState({ ScannedResultItems_checked: checked.map((data, key) => { return !checked_none; }), });
                                    }
                                }} className="pointer" title="Select All">
                                    <input type='checkbox' className='form-check-input' readOnly={true}
                                        checked={this.state.ScannedResultItems_checked.length > 0 ? this.state.ScannedResultItems_checked.indexOf(false) < 0 : false}></input>
                                    <br />Select
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.ScannedResultModalComponent()}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleScannedResultModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ScannedResult_ResetValues()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.ScannedResult_CreateOrUpdate_Items_ViaApi()} disabled={this.state.isUpdating}>Proceed</Button>
                </Modal.Footer>
            </Modal >

        </>);
    }
}