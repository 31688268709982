import React from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import html2pdf from "html2pdf.js";

import { CapitalizeJsonKeys, CheckNullValue, CheckObjectBoolean, CheckObjectStringEmpty, Delay, DelayUntil } from "../utilities/GlobalFunctions";
import { GlobalSetting } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";

let toggle_question_answer_handlers = [];

export default class PreviewQuestionSetComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        //Preview.
        UniqueId: '',
        QuestionSet: null,
        QuestionSet_Components: null,
        QuestionSet_Questions_Toggle: [],
        PreviewQuestionSetModal_Toggle: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {

    }

    //#region === Question Set === Preview === start ===
    FetchQuestionSetViaApi = async (uniqueId = '') => {
        useAppService.getState().setModal('', 'Fetching question set...', null, AlertMode.Loading);
        let questionSet = null;
        let done = false;
        await fetch(`${GlobalSetting.ApiUrl}Api/LearningCentre/Quiz/QuestionSet/Root/Get/${0}/${0}/${uniqueId}`,
            // Api/LearningCentre/Quiz/QuestionSet/Root/Get/{organizerId}/{authorId}/{uniqueId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('FetchQuestionSetViaApi (response) =\n' + JSON.stringify(data));
                if (CheckObjectBoolean(data, 'success'))
                    questionSet = CapitalizeJsonKeys(data.data);
                else
                    useAppService.getState().setModal('Failed to fetch Question Set', data.message);
                done = true;
            })
            .catch(error => {
                useAppService.getState().setModal('Failed to fetch Question Set (Error)', error.message);
                done = true;
            });
        await DelayUntil(() => done === true);
        if (questionSet !== null) {
            await Delay(1000);
            useAppService.getState().setModal();
            await Delay(500);
        }
        this.setState({ QuestionSet: questionSet, });
    }
    TogglePreviewQuestionSetModal = async (uniqueId = '') => {
        if (this.state.isDevMode)
            console.log('TogglePreviewQuestionSetModal (UniqueId) =\n' + uniqueId);
        const toggle = !this.state.PreviewQuestionSetModal_Toggle;
        let htmlTexts = '';
        let questions_toggle = [];
        if (toggle) {
            if (CheckNullValue(uniqueId) === null)
                return null;
            if (this.state.QuestionSet === null)
                await this.FetchQuestionSetViaApi(uniqueId);
            if (this.state.QuestionSet !== null) {
                useAppService.getState().setModal('', 'Populating question set...', null, AlertMode.Loading);
                const { Questions } = this.state.QuestionSet;
                if (Array.isArray(Questions) && Questions.length > 0) {
                    questions_toggle = Questions.map((data, key) => { return false; });
                    this.setState({
                        QuestionSet_Questions_Toggle: questions_toggle,
                    });
                }
                htmlTexts = await this.PopulateQuestionSetComponent_HtmlText();
                await Delay(1000);
            }
        }
        else {
            this.setState({
                PreviewQuestionSetModal_Toggle: false,
            });
            await Delay(200);
        }
        this.setState({
            UniqueId: uniqueId,
            QuestionSet: toggle === false ? null : this.state.QuestionSet,
            QuestionSet_Components: htmlTexts,
            PreviewQuestionSetModal_Toggle: this.state.QuestionSet === null ? false : toggle,
            QuestionSet_Questions_Toggle: questions_toggle,
        }, () => {
            useAppService.getState().setModal();
            if (toggle) {
                let buttons = document.querySelectorAll('.btn-toggle-answer');
                toggle_question_answer_handlers = [];
                buttons.forEach((btn) => {
                    toggle_question_answer_handlers[btn.id] = () => this.SetToggleQuestionSetQuestion(btn.id);
                    btn.addEventListener('click', toggle_question_answer_handlers[btn.id]);
                });
            }
            else {
                let buttons = document.querySelectorAll('.btn-toggle-answer');
                buttons.forEach((btn) => {
                    // btn.removeEventListener('click', () => this.SetToggleQuestionSetQuestion(btn.id));
                    btn.removeEventListener('click', toggle_question_answer_handlers[btn.id]);
                });
                toggle_question_answer_handlers = [];
            }
        });
    }
    SetToggleQuestionSetQuestion = (id = '') => {
        if (this.state.isDevMode)
            console.log(`ToggleQuestionSetQuestion (${id})`);
        if (CheckNullValue(id) === null)
            return null;
        let index = -1;
        const tmp = id.split('_');
        if (tmp.length > 1)
            if (Number(tmp[1]) > -1)
                index = Number(tmp[1]);
        if (index < 0)
            return null;
        let toggles = this.state.QuestionSet_Questions_Toggle;
        toggles[index] = !toggles[index];
        this.setState({
            QuestionSet_Questions_Toggle: toggles,
        }, async () => {
            let buttons = document.querySelectorAll('.btn-toggle-answer');
            buttons.forEach((btn) => {
                btn.removeEventListener('click', () => this.SetToggleQuestionSetQuestion(btn.id));
            });
            toggle_question_answer_handlers = [];
            const htmlTexts = await this.PopulateQuestionSetComponent_HtmlText();
            this.setState({
                QuestionSet_Components: htmlTexts,
            }, () => {
                let buttons = document.querySelectorAll('.btn-toggle-answer');
                buttons.forEach((btn) => {
                    toggle_question_answer_handlers[btn.id] = () => this.SetToggleQuestionSetQuestion(btn.id);
                    btn.addEventListener('click', toggle_question_answer_handlers[btn.id]);
                });
            });
        });
    }
    PopulateQuestionSetComponent_HtmlText = async () => {
        let htmlTexts = '';
        const questionSet = this.state.QuestionSet;
        const toggles = this.state.QuestionSet_Questions_Toggle;
        if (questionSet === null)
            return null;
        const { Questions } = questionSet;
        if (Questions !== undefined && Array.isArray(Questions)) {
            for (let i = 0; i < Questions.length; i++) {
                const question = Questions[i];
                const content = CheckObjectStringEmpty(question, 'Content');
                const pictureUrl = CheckObjectStringEmpty(question, 'PictureUrl');
                const selections = CheckObjectStringEmpty(question, 'Selection');
                htmlTexts += "<div style='border:1px solid blue;border-radius:5px;padding:5px 10px;margin-bottom:7px;'>";
                htmlTexts += "<div class='row question-header-div'>";
                htmlTexts += "<div class='col title-div'>Question #" + (i + 1) + "</div>";
                const btnToggleAnswer = toggles[i] ? "" : "<button type='button' class='btn btn-primary btn-toggle-answer' id='btn-toggle-answer_" + i + "'>Show Answer</button>";
                htmlTexts += "<div class='col btn-toggle-answer-div'>" + btnToggleAnswer + "</div>";
                htmlTexts += "</div>";
                htmlTexts += "<table width='100%' cellpadding='10'><tbody>";
                //Content.
                htmlTexts += "<tr><td>" + this.FilterQuestionContent_Preview(content, false) + "</td></tr>";
                //Picture.
                if (pictureUrl !== '') {
                    const pictureUrl_blobUrl = await this.GetPictureUrl(pictureUrl);
                    htmlTexts += "<tr><td><img src='" + pictureUrl_blobUrl + "' alt='' width='100%'></img></td></tr>";
                    // htmlTexts += "<tr><td><img src='" + this.GetPictureUrl(pictureUrl) + "' alt='' width='100%'></img></td></tr>";
                }
                //Selections.
                htmlTexts += "<tr><td><div style='display:flex;flex-direction:column;row-gap:10px;'>";
                if (selections === '') {
                    htmlTexts += "<span>No Option is added.</span>";
                }
                else {
                    const selection_options = selections.split(';');
                    if (Array.isArray(selection_options)) {
                        for (let key = 0; key < selection_options.length; key++) {
                            const option = selection_options[key];
                            if (option !== '' && option.includes(':')) {
                                const value = option.split(':');
                                htmlTexts += "<div key='ao-" + i + '-' + key + "' style='display:flex;'>";
                                htmlTexts += "<div style='width:40px;text-align:center' class='icon-color'>";
                                if (value[0] === CheckObjectStringEmpty(question, 'Answer') && toggles[i])
                                    htmlTexts += "<span class='badge bg-primary' style='font-size:14px;'>" + value[0] + "</span>";
                                else
                                    htmlTexts += "<span class='badge black'><b>" + value[0] + "</b></span>";
                                htmlTexts += "</div><div style='width:400px;'>";
                                if (value.length > 1) {
                                    if (value[1].includes('_pk_')) {
                                        const ans_opti_blobUrl = await this.GetPictureUrl(value[1]);
                                        htmlTexts += "<img src='" + ans_opti_blobUrl + "' alt='' width='100%'></img>";
                                        // htmlTexts += "<img src='" + this.GetPictureUrl(value[1]) + "' alt='' width='100%'></img>";
                                    }
                                    else {
                                        htmlTexts += "<span>" + value[1] + "</span>";
                                    }
                                }
                                htmlTexts += "</div></div>";
                            }
                        }
                    }
                }
                htmlTexts += "</div></td></tr></tbody></table>";
                htmlTexts += "</div>";
            }
        }
        htmlTexts += "<div class='watermark'></div>";
        return `<div style='display:flex;flex-direction:column;row-gap:10px;' id='Div_Room_QuestionSet'>${htmlTexts}</div>`;
    }
    FilterQuestionContent_Preview = (_content = '', _save = false) => {
        //new line tag.
        // _content = _content.replace(new RegExp("\n", "g"), "<br />");
        _content = _content.replaceAll("\n", "<br />");
        //img tag.
        if (_save === false) {
            _content = _content.replace(new RegExp("<img>", "g"), "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/JPG/");
            _content = _content.replace(new RegExp("</img>", "g"), ".jpg' alt='' width='100%' />");
        }
        return _content;
    }
    GetPictureUrl = async (_pictureName = '') => {
        if (_pictureName === '')
            return '';
        return 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _pictureName + '.jpg';
    }
    DownloadQuestionSetInPDF = async (uniqueId = '') => {
        if (this.state.isDevMode)
            console.log('DownloadQuestionSetInPDF (UniqueId) =\n' + uniqueId);
        let htmlTexts = '';
        if (this.state.QuestionSet === null)
            await this.FetchQuestionSetViaApi(uniqueId);
        if (this.state.QuestionSet !== null) {
            useAppService.getState().setModal('', 'Populating question set...', null, AlertMode.Loading);
            const { Questions } = this.state.QuestionSet;
            if (Array.isArray(Questions) && Questions.length > 0) {
                const questions_toggle = Questions.map((data, key) => { return true; });
                this.setState({ QuestionSet_Questions_Toggle: questions_toggle, });
                await Delay(200);
            }
            htmlTexts = await this.PopulateQuestionSetComponent_HtmlText();
        }
        this.setState({
            QuestionSet_Components: htmlTexts,
        });
        await Delay(1000);
        if (htmlTexts !== '') {
            const element = document.getElementById('Div_Room_QuestionSet_hidden');
            if (element !== null && element !== undefined) {
                await Delay(500);
                useAppService.getState().setModal('', 'Downloading question set...', null, AlertMode.Loading);
                // html2pdf(element);
                var opt = {
                    margin: 5,
                    filename: `QuestionSet_${moment().format('(YYYY-MM-DD_HHmm)')}.pdf`,
                    // image: { type: 'jpg', quality: 0.2 },
                    image: { type: 'jpeg', quality: 0.2 },
                    // image: { type: 'png', quality: 0.2 },
                    html2canvas: {
                        scale: 1,
                        useCORS: true,
                        allowTaint: true,
                        // proxy: 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/',
                        // proxy: 'https://ikeynew.blob.core.windows.net',
                        proxy: 'https://ile-portal.ikey.my',
                        logging: false,
                    },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', precision: '12', compress: true },
                    pagebreak: {
                        mode: ['avoid-all'],
                        // after: ['.pagebreak']
                    }
                };
                html2pdf().set(opt).from(element).save().then(() => {
                    useAppService.getState().setModal();
                });
                // html2pdf(element, opt);
                // setTimeout(() => {
                //     useAppService.getState().setModal();
                // }, 5000);
            }
        }
        else {
            useAppService.getState().setModal('', 'Failed to save as PDF.');
        }
    }
    //#endregion === Question Set === Preview === end ===

    render = () => {
        return (<div className="">

            {/* Report - Preview Question Set - Modal */}
            <Modal show={this.state.PreviewQuestionSetModal_Toggle} onHide={() => this.TogglePreviewQuestionSetModal()} centered>
                <Modal.Header closeButton><Modal.Title>Question Set (Preview)</Modal.Title></Modal.Header>
                <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.QuestionSet_Components }}></div></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="pull-left" onClick={() => this.DownloadQuestionSetInPDF(this.state.UniqueId)}>Download Question Set (PDF)</Button>
                    <Button variant="secondary" onClick={() => this.TogglePreviewQuestionSetModal()}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div hidden={true}><div id='Div_Room_QuestionSet_hidden' dangerouslySetInnerHTML={{ __html: this.state.QuestionSet_Components }}></div></div>

        </div>);
    }
}