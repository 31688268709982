import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";

import { GlobalSetting, InputType, LayoutScreen, PermissionAccessType } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DoNothing, GetInputComponent, GetPostParams, GetPropIds, PermissionAccess, RandomId, SetTempTarget } from "../utilities/GlobalFunctions";
import { CreateOrganizer_ViaAPI, GetAllOrganizers_ViaAPI, UpdateOrganizer_ViaAPI, UploadPictureFileForOrganizer_ViaAPI } from "../services/OrganizerService";
import { SearchGovernmentSchools_ViaAPI } from "../services/GovernmentSchoolService";
import { AlertMode } from "./AlertComponent";

const DisplaySetting = {
    SchoolCode: 'School Code',
}

const SettingInput = {
    //ordering matters.
    CategoryList: 'CategoryList',
    Active: 'Active',
    CheckedItem: 'CheckedItem',
    None: 'None',   //must at the bottom.
};

const BulkSetting = {
    //ordering matters.
    CategoryList: 'CategoryList',
    Active: 'Active',
};

export default class ManageOrganizerScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        screen: LayoutScreen.None,
        isSuperAdmin: false,
        isAuthor: false,

        IsLoading: false,
        IsListLoaded: false,
        List: [],

        SearchSchoolByParamsModal_Toggle: false,
        SearchSchoolByParams_Processing: false,
        SearchBySchoolCode: '',
        SearchBySchoolName: '',
        SearchByCountryState: '',
        SearchByDistrictArea: '',
        SearchSchoolResultModal_Toggle: false,
        SchoolList: [],
        SelectedSchool: null,

        EditOrganizerModal_Toggle: false,
        EditOrganizerIndex: -1,
        EditOrganizerObject: null,
        EditOrganizerCache: null,
        EditOrganizerNew: false,
        EditOrganizer_Processing: false,
        AttachedPictureFile: null,

        //2024.12.12
        CategoryOptions: [],
        EditOrganizerCategoryModal_Toggle: false,

        //2024.12.12
        //Bulk Edit.
        BulkEdit_CheckedItems: [],
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,

        //2024.12.12
        //Display Setting.
        DisplaySettingOptions: [],
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageOrganizer);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.Dashboard),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
            DisplaySettingOptions: Object.keys(DisplaySetting).map((data, key) => { return true; }),
        });
        await Delay(200);
        await this.CheckPermissions();      //2024.12.09
        if (this.state.isAuthor === false || this.state.PA_View === false)
            return null;
        await this.LoadList_ViaApi();
    }

    //2024.12.09
    CheckPermissions = async () => {
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Download),
        });
        await Delay(0);
    }

    //#region === List ===
    //get all organizers.
    LoadList_ViaApi = async () => {

        this.setState({
            IsLoading: true,
            IsListLoaded: false,
            List: [],
        });
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await GetAllOrganizers_ViaAPI(authorId);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to retrieve Organizer list.<br /><br />Error:' + errorMessage);
        }
        else {
            let _List = [];
            if (Array.isArray(data.list)) {
                data.list.forEach((item, key) => {
                    _List.push(this.PopulateOrganizerModal(item));
                });
                _List.sort((a, b) => a.Id - b.Id);
            }

            //2024.12.12
            let _categoryOptions = [];
            if (Array.isArray(data.categoryOptions)) {
                _categoryOptions = CapitalizeJsonKeys(data.categoryOptions);
                _categoryOptions.sort((a, b) => a.Id - b.Id);
                if (this.state.isDevMode)
                    console.log('CategoryOptions (From List) \n' + JSON.stringify(_categoryOptions));
            }

            this.setState({
                List: _List,
                CategoryOptions: _categoryOptions,
                BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
            });
        }
        this.setState({
            IsLoading: false,
            IsListLoaded: true,
        });
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];

            //2024.12.12
            const checkedItems = this.state.BulkEdit_CheckedItems;
            const displaySettings = Object.keys(DisplaySetting);
            const displayOption = this.state.DisplaySettingOptions;

            this.state.List.map((data, key) => {

                //2024.12.12
                let hideOrganizer = false;
                for (let d = 0; d < displaySettings.length; d++) {
                    if (displayOption[d]) {
                        hideOrganizer = CheckObjectStringEmpty(data, displaySettings[d]) === '';
                    }
                    if (hideOrganizer)
                        break;
                }

                return listItems.push(
                    <tr key={'list-item-' + key}
                        className={`${checkedItems[key] ? 'setting-classroom-tr-class' : ''}`}
                        hidden={hideOrganizer}
                    >
                        <td align='center' className="pointer" onClick={() => this.ToggleItemChecked(key)}>
                            <span>{key + 1}</span>
                            <input type='checkbox' className="form-check form-check-input pointer"
                                checked={checkedItems[key]} readOnly={true}
                            ></input>
                        </td>
                        <td>
                            <b>{CheckObjectStringEmpty(data, 'DisplayName', '-')}</b><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'Name', '-')})</span><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'IdentityGuid', '-')})</span>
                        </td>
                        <td>
                            {CheckObjectStringEmpty(data, 'SchoolName', '-')}
                            {CheckObjectNullValue(data, 'SchoolCode') === null ? <></> :
                                <><br />School Code: <b>{CheckObjectStringEmpty(data, 'SchoolCode', '-')}</b></>}
                        </td>
                        <td>
                            <div dangerouslySetInnerHTML={{
                                __html: this.FormatSchoolCompleteName(data)
                            }}></div>
                        </td>
                        {/* <td align="center" style={{ verticalAlign: 'top', padding: 5, display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <div className='row' style={{ padding: 5 }}>
                                <div className='col'>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.Toggle_EditOrganizerModal(key)}
                                        style={{ width: '100%' }}
                                        disabled={this.state.IsLoading}
                                    >Edit</button>
                                </div>
                            </div>
                        </td> */}
                        <td>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.Toggle_EditOrganizerModal(key)}
                                    style={{ width: '100%' }}
                                    disabled={this.state.IsLoading}
                                >Edit</button>
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => this.Toggle_EditOrganizerCategoryModal(key)}
                                    style={{ width: '100%' }}
                                    disabled={this.state.IsLoading}
                                >Category</button>
                            </div>
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.isLoading || !this.state.IsListLoaded ?
            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
            : <tr><td colSpan='15' align='center'>list is empty</td></tr>;
    }
    PopulateOrganizerModal = (modal = null) => {
        //2024.12.12
        let categoryList = CheckObjectNullValue(modal, 'CategoryList', []);
        if (Array.isArray(categoryList) && categoryList.length > 0)
            categoryList.sort((a, b) => a.CategoryId - b.CategoryId);
        else
            categoryList = [];

        return {
            Id: CheckObjectNumber(modal, 'Id'),
            Name: CheckObjectStringEmpty(modal, 'Name'),
            DisplayName: CheckObjectStringEmpty(modal, 'DisplayName'),
            IdentityGuid: CheckObjectStringEmpty(modal, 'IdentityGuid'),
            Active: CheckObjectBoolean(modal, 'Active'),
            SchoolCode: CheckObjectStringEmpty(modal, 'SchoolCode'),
            NationalState: CheckObjectStringEmpty(modal, 'NationalState'),
            DistrictArea: CheckObjectStringEmpty(modal, 'DistrictArea'),
            SchoolName: CheckObjectStringEmpty(modal, 'SchoolName'),
            SchoolCompleteName: CheckObjectStringEmpty(modal, 'SchoolCompleteName'),
            SchoolAddress: CheckObjectStringEmpty(modal, 'SchoolAddress'),
            LogoId: CheckObjectNumber(modal, 'LogoId'),
            LogoUrl: CheckObjectStringEmpty(modal, 'LogoUrl'),
            IsDemo: CheckObjectBoolean(modal, 'IsDemo'),                //2024.12.09
            CategoryList: categoryList,                                 //2024.12.12
        };
    }
    FormatSchoolCompleteName = (data = null) => {
        if (data === null)
            return '';

        if (CheckObjectNullValue(data, 'SchoolCode') === null)
            return '-';

        const name = CheckObjectStringEmpty(data, 'SchoolCompleteName');
        if (name.includes(' - ')) {
            let array = name.split(' - ');
            if (Array.isArray(array) && array.length > 0) {
                array.shift();
                array[0] = '<span style="font-weight: 400;">' + array[0] + '</span>';
                return array.join('<br />');
            }
        }
        return '-';
    }
    //#endregion === List ===

    //#region === Organizer - Edit / New ===
    //create new organizer.
    CreateOrganizer = async () => {
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal('Creation Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'creating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await CreateOrganizer_ViaAPI(authorId, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to create new Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                List: data,
            });
            useAppService.getState().setModal('', 'Organizer has been created.');
            await this.Toggle_EditOrganizerModal();
            this.LoadList_ViaApi();
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //update or remove.
    UpdateOrganizer = async (remove = false) => {
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal((remove ? 'Removal' : 'Update') + ' Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'updating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await UpdateOrganizer_ViaAPI(authorId, remove, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to update Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            let list = this.state.List;
            const t_data = this.PopulateOrganizerModal(data);
            const findIndex = list.findIndex(x => x.Id === CheckObjectNumber(t_data, 'Id'));
            if (findIndex > -1)
                list[findIndex] = t_data;
            this.setState({
                List: list,
            });
            useAppService.getState().setModal('', 'Organizer has been ' + (remove ? 'removed' : 'updated') + '.');
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    Toggle_EditOrganizerModal = async (index = -1, create = false) => {
        const toggle = !this.state.EditOrganizerModal_Toggle;
        if (!toggle) {
            this.setState({
                EditOrganizerModal_Toggle: toggle,
            });
            await Delay(200);
        }
        this.setState({
            EditOrganizerIndex: index,
            EditOrganizerNew: create,
            AttachedPictureFile: null,
            SelectedCategories: [],         //2024.12.12
        }, async () => {
            let obj = null;
            if (create)
                obj = this.PopulateOrganizerModal();
            else
                obj = !toggle || index < 0 ? null : this.PopulateOrganizerModal(this.state.List[index]);

            await Delay(0);
            SetTempTarget(obj);
            await Delay(0);

            this.setState({
                EditOrganizerObject: obj,
                EditOrganizerCache: obj === null ? null : this.PopulateOrganizerModal(obj),
            });
        });
        if (toggle) {
            await Delay(200);
            this.setState({
                EditOrganizerModal_Toggle: toggle,
            });
        }
    }
    ResetEditOrganizerObject = () => {
        this.setState({
            EditOrganizerObject: this.PopulateOrganizerModal(this.state.EditOrganizerCache),
        });
    }
    SaveOrganizerObject = (obj = null) => {
        this.setState({
            EditOrganizerObject: obj,
        });
    }
    EditOrganizerComponents = () => {
        const organizer = this.state.EditOrganizerObject;
        if (organizer === null)
            return null;

        let components = [];

        //Name.
        components.push(<div className="form-group">
            <label>Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'Name', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //DisplayName.
        components.push(<div className="form-group">
            <label>Display Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DisplayName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //IdentityGuid.
        components.push(<div className="form-group">
            <label>GUID</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'IdentityGuid', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //2024.12.12
        //Category.
        components.push(<div className="form-group">
            <label>Category</label>
            {this.OrganizerCategoryComponent()}
        </div>);

        //NationalState.
        components.push(<div className="form-group">
            <label>Country State</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'NationalState', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //DistrictArea.
        components.push(<div className="form-group">
            <label>District Area</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DistrictArea', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolCode.
        components.push(<div className="form-group">
            <label>School Code</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolCode', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolName.
        components.push(<div className="form-group">
            <label>School Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolCompleteName.
        components.push(<div className="form-group">
            <label>Complete School Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolCompleteName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolAddress.
        components.push(<div className="form-group">
            <label>School Address</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolAddress', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //Active.
        components.push(<div className="form-group">
            <label>Active</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'Active', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //IsDemo.
        components.push(<div className="form-group">
            <label>Demo</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'IsDemo', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //Logo.
        if (this.state.EditOrganizerNew || this.state.SelectedSchool !== null) { }
        else {
            const logoUrl = CheckObjectStringEmpty(organizer, 'LogoUrl');
            const logoFileName_splits = logoUrl.split('/');
            const logoFileName = logoUrl.length === 0 ? 'Logo' : logoFileName_splits[logoFileName_splits.length - 1];
            components.push(<div className="form-group">
                <label htmlFor="schoolLogo">Logo image file</label>
                <input
                    type="file"
                    onChange={this.onAttachPictureFile}
                    style={{ width: '100%' }}
                    name='schoolLogo'
                    accept='image/png,image/jpeg'
                />
                {
                    logoUrl === '' ? null :
                        <button type="button" style={{ borderStyle: 'none', backgroundColor: 'transparent', marginTop: 15 }}
                            onClick={() => window.open(logoUrl, '_blank')}
                            title={logoFileName}>
                            <img src={logoUrl} alt={logoFileName} style={{ maxWidth: 155, maxHeight: 155 }} />
                        </button>
                }
            </div>);
        }

        return (components);
    }
    onAttachPictureFile = (event) => {
        this.setState({
            AttachedPictureFile: event === null ? null : event.target.files[0],
        }, () => {
            this.UploadPictureFileForOrganizer();
        });
    }
    UploadPictureFileForOrganizer = async () => {
        const obj = this.state.EditOrganizerObject;
        if (obj === null) {
            useAppService.getState().setModal('Upload Failed', 'Invalid organizer object.');
            return null;
        }

        //Convert Picture to Base64.
        let _base64 = '';
        if (this.state.AttachedPictureFile !== null) {
            let reader = new FileReader();
            reader.onload = (evt) => {
                // _base64 = btoa(reader.result);
                _base64 = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
            };
            reader.readAsArrayBuffer(this.state.AttachedPictureFile);
        }
        await Delay(300);
        if (CheckNullValue(_base64) === null) {
            useAppService.getState().setModal('Upload Failed', 'image file conversion error.');
            return null;
        }

        const _fileName = CheckStringEmpty(this.state.AttachedPictureFile.name).toLowerCase();
        let _fileType = '';
        let _fileExt = '';
        if (_fileName.includes('.jpg')) {
            _fileType = 'image/jpeg';
            _fileExt = '.jpg';
        }
        else if (_fileName.includes('.png')) {
            _fileType = 'image/png';
            _fileExt = '.png';
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'uploading image file...', null, AlertMode.Loading);

        const fileName = this.GetGuid(CheckObjectStringEmpty(obj, 'SchoolName'))
            + '-' + CheckObjectStringEmpty(obj, 'SchoolCode') + '-' + RandomId();

        //2024.12.09
        let t_organizerId = 0;
        if (this.state.EditOrganizerIndex > -1) {
            t_organizerId = CheckObjectNumber(this.state.List[this.state.EditOrganizerIndex], 'Id')
        }

        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, message } = await UploadPictureFileForOrganizer_ViaAPI({
            AuthorId: authorId,
            OrganizerId: t_organizerId,
            FileExt: _fileExt,
            FileType: _fileType,
            FileName: fileName,
            Base64: _base64,
        });
        if (!success) {
            useAppService.getState().setModal('', 'Failed to upload image file.<br /><br />Error:' + message);
        }
        else {
            // const obj = this.PopulateOrganizerModal(CapitalizeJsonKeys(data));
            // this.setState({
            //     EditOrganizerObject: obj,
            //     EditOrganizerCache: JSON.parse(JSON.stringify(obj)),
            // });
            const index = this.state.EditOrganizerIndex;
            await this.LoadList_ViaApi();
            await Delay(0);
            await this.Toggle_EditOrganizerModal();
            await Delay(200);
            await this.Toggle_EditOrganizerModal(index);
            await Delay(0);
            useAppService.getState().setModal('', "Organizer's logo has been updated.");
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //#endregion === Organizer - Edit / New ===

    //#region === Gov Schools ===
    Toggle_SearchSchoolByParams = (forceClose = false) => {
        this.setState({
            SearchSchoolByParamsModal_Toggle: forceClose ? false : !this.state.SearchSchoolByParamsModal_Toggle,
            SearchSchoolByParams_Processing: false,
            SearchBySchoolCode: '',
            SearchBySchoolName: '',
            SearchByCountryState: '',
            SearchByDistrictArea: '',
        }, () => {
            if (this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                    SelectedSchool: null,
                });
            }
        });
    }
    // //get all schools.
    // GetAllGovernmentSchools_ViaAPI = async () => {
    //     this.setState({
    //         SearchBySchoolCode: '',
    //         SearchBySchoolName: '',
    //         SearchByCountryState: '',
    //         SearchByDistrictArea: ''
    //     });
    //     // await this.searchGovernmentSchools_ViaAPI();
    // }
    //get schools by params.
    SearchGovernmentSchools_ViaAPI = async () => {
        this.setState({
            SearchSchoolByParams_Processing: true,
            SchoolList: [],
        });

        let tableList = [];
        const { success, message, list } = await SearchGovernmentSchools_ViaAPI(
            this.state.SearchBySchoolCode, this.state.SearchBySchoolName,
            this.state.SearchByCountryState, this.state.SearchByDistrictArea
        );
        if (success)
            tableList = list;
        else
            useAppService.getState().setModal('Search (Failed)', 'Invalid Operation.<br /><br />Error:<br />' + message);
        if (Array.isArray(tableList) && tableList.length > 0) {
            for (let i = 0; i < tableList.length; i++) {
                tableList[i].DistrictArea = tableList[i].PPD.split(' ').slice(1, 5).join(' ');
            }
        }
        this.setState({
            SchoolList: tableList,
            SearchSchoolByParams_Processing: false,
        }, () => {
            this.Toggle_SeachSchoolResultModal();
        });
    }
    Toggle_SeachSchoolResultModal = () => {
        this.setState({
            SearchSchoolResultModal_Toggle: !this.state.SearchSchoolResultModal_Toggle,
        }, () => {
            if (!this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                });
            }
        });
    }
    ConfirmSelectedSchoolResult = async () => {
        const selectedSchool = this.state.SelectedSchool;
        if (selectedSchool === null)
            return null;

        let modal = this.state.EditOrganizerObject;
        if (modal === null)
            modal = this.PopulateOrganizerModal();

        const schoolName = CheckObjectStringEmpty(selectedSchool, 'SchoolName');
        const guid = this.GetGuid(schoolName);

        modal['Id'] = CheckObjectNumber(modal, 'Id');
        modal['Name'] = guid.toUpperCase();
        modal['DisplayName'] = schoolName;
        modal['IdentityGuid'] = guid.toLowerCase();
        modal['Active'] = true;
        modal['SchoolCode'] = CheckObjectStringEmpty(selectedSchool, 'SchoolCode');
        modal['NationalState'] = CheckObjectStringEmpty(selectedSchool, 'CountryState');
        modal['DistrictArea'] = CheckObjectStringEmpty(selectedSchool, 'PPD');
        modal['SchoolName'] = schoolName;
        modal['SchoolCompleteName'] = CheckObjectStringEmpty(selectedSchool, 'CompleteSchoolName');
        modal['SchoolAddress'] = CheckObjectStringEmpty(selectedSchool, 'Address');

        // modal['LogoId'] = CheckObjectNumber(modal, 'LogoId');
        // modal['LogoUrl'] = CheckObjectStringEmpty(modal, 'LogoUrl');

        await Delay(0);
        SetTempTarget(modal);
        this.SaveOrganizerObject(modal);
        await Delay(0);
        this.Toggle_SeachSchoolResultModal();
        await Delay(0);
        this.Toggle_SearchSchoolByParams(true);
    }
    GetGuid = (schoolName = '') => {

        //#region   

        // let guid = '';

        // if (schoolName.includes('KEBANGSAAN') && schoolName.includes('MENENGAH') === false) {
        //     if (schoolName.includes('(CINA)')) {
        //         if (schoolName.includes('JENIS'))
        //             guid = 'SJKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //         else
        //             guid = 'SKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //     }
        //     else if (schoolName.includes('(FELDA)'))
        //         guid = 'SKFELDA' + schoolName.split('(FELDA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SJKT' + schoolName.split('(TAMIL)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL & TELUGU)'))
        //         guid = 'SJKTT' + schoolName.split('(TAMIL & TELUGU)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (1)'))
        //         guid = 'SK1' + schoolName.split('SEKOLAH KEBANGSAAN (1)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (2)'))
        //         guid = 'SK2' + schoolName.split('SEKOLAH KEBANGSAAN (2)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(A)'))
        //         guid = 'SKA' + schoolName.split('(A)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(AGAMA)'))
        //         guid = 'SKAG' + schoolName.split('(AGAMA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(ASLI)'))
        //         guid = 'SKASLI' + schoolName.split('(ASLI)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(L)'))
        //         guid = 'SKL' + schoolName.split('(L)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(LKTP)'))
        //         guid = 'SKLKTP' + schoolName.split('(LKTP)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(P)'))
        //         guid = 'SKP' + schoolName.split('(P)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(RTBK)'))
        //         guid = 'SKRTBK' + schoolName.split('(RTBK)')[1].replace(/\s/g, '');

        //     else
        //         guid = 'SK' + schoolName.replace('SEKOLAH KEBANGSAAN', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN') === false) {
        //     if (schoolName.includes('(ARAB)'))
        //         guid = 'SMAA' + schoolName.split('(ARAB)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(') === false && schoolName.includes('AGAMA'))
        //         guid = 'SMAG' + schoolName.split('AGAMA')[1].replace(/\s/g, '');
        //     else
        //         guid = 'SM' + schoolName.replace('SEKOLAH MENENGAH', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN')) {

        // }

        // else if (schoolName.includes('SEKOLAH KHAS') === false)
        //     guid = 'SKHAS' + schoolName.replace('SEKOLAH KHAS').replace(/\s/g, '');




        // if (schoolName.includes('SEKOLAH MENENGAH KEBANGSAAN')) {
        //     guid = 'SMK' + schoolName.replace('SEKOLAH MENENGAH KEBANGSAAN', '').replace(symbolRegex, '');
        // }
        // else if (schoolName.includes('SEKOLAH JENIS KEBANGSAAN')) {
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SJKC' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (TAMIL)', '').replace(symbolRegex, '');
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else
        //         guid = 'SJK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN', '').replace(symbolRegex, '');
        // }

        //#endregion

        const symbolRegex = /[\s.,#!$%^&*;:{}=\-_`~()]/g;

        let guid = schoolName.replace(symbolRegex, '');
        guid = guid.replace('SEKOLAH', 'S').replace('JENIS', 'J').replace('KEBANGSAAN', 'K').replace('MENENGAH', 'M');
        guid = guid.replace('CINA', 'C').replace('TAMIL', 'T').replace('TAMIL & TELUGU', 'TT').replace('FELDA', 'F');
        // guid = guid.split(' ').map(word => { return word.charAt(0); }).join('');

        if (CheckNullValue(guid) === null)
            guid = schoolName.replace(symbolRegex, '');

        if (this.state.isDevMode)
            console.log('GetGuid = ' + guid);

        return guid;
    }
    //#endregion === Gov Schools ===

    //#region === Category - Add/Remove ===
    //2024.12.12
    Toggle_EditOrganizerCategoryModal = async (index = -1) => {
        const toggle = !this.state.EditOrganizerCategoryModal_Toggle;
        if (!toggle) {
            this.setState({
                EditOrganizerCategoryModal_Toggle: false,
            });
            await Delay(200);
        }
        const target = index < 0 ? null : this.PopulateOrganizerModal(this.state.List[index]);
        this.setState({
            EditOrganizerIndex: index,
            EditOrganizerObject: target,
            EditOrganizerCache: index < 0 ? null : this.PopulateOrganizerModal(target),
        });
        if (toggle) {
            if (index > -1)
                SetTempTarget(target);
            this.setState({
                EditOrganizerCategoryModal_Toggle: true,
            });
        }
    }
    //2024.12.12
    OrganizerCategoryComponent = () => {
        let components = [];
        // if (this.state.EditOrganizer_Processing)
        //     return (<ProgressBar animated now={100} className='progressbar1' style={{ padding: 10 }} />);
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            let selectedCategories = [];
            let target = this.state.EditOrganizerObject;
            if (target !== null) {
                if (Array.isArray(target['CategoryList']))
                    selectedCategories = target['CategoryList'];
                else
                    target['CategoryList'] = [];
            }
            let table_categoryOptions = [];
            categoryOptions.map((option, key) => {
                const isChecked = selectedCategories.length > 0 ? selectedCategories.findIndex(x => x.CategoryId === option.Id) > -1 : false;
                return table_categoryOptions.push(<tr
                    onClick={async () => {
                        if (target !== null) {
                            const findIndex = selectedCategories.findIndex(x => x.CategoryId === option.Id);
                            if (findIndex < 0)
                                selectedCategories.push({ CategoryId: option.Id, Name: option.Label });
                            else
                                selectedCategories.splice(findIndex, 1);
                            selectedCategories.sort((a, b) => a.CategoryId - b.CategoryId);
                            target['CategoryList'] = selectedCategories;
                            SetTempTarget(target);
                            this.SaveOrganizerObject(target);
                            await Delay(0);
                            // if (this.state.isDevMode)
                            //     console.log(JSON.stringify(GetTempTarget()));
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <td width='42'>
                        <input type='checkbox' className="form-check form-check-input" readOnly={true} checked={isChecked} style={{ margin: 0 }}></input>
                    </td>
                    <td>{option.Label}</td>
                </tr>);
            });
            components.push(<table className="table table-hover tb-no-border-no-margin"><tbody>
                {table_categoryOptions}
            </tbody></table>);
        }
        return (components);
    }
    //#endregion === Category - Add/Remove ===

    //#region === bulk edit ===
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal('Bulk Edit Organizer(s)', 'Invalid permission.');
            return null;
        }
        this.BulkEdit_ResetSetting();
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Category List.
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            const categoryList_setting_index = Object.values(SettingInput).indexOf(SettingInput.CategoryList);
            const categoryList_setting_checked = setting_checked[categoryList_setting_index];
            components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (categoryList_setting_checked ? 'bg-lightskyblue' : '')}`}>
                <div className="form-group width-max">
                    <label>Category</label>
                    <table className="table table-hover tb-no-border-no-margin"><tbody>
                        {
                            categoryOptions.map((option, key) => {
                                const isChecked = Array.isArray(setting[categoryList_setting_index].value)
                                    // && key < setting[categoryList_setting_index].value.length
                                    ? setting[categoryList_setting_index].value.findIndex(x => x.CategoryId === option.Id) > -1 : false;
                                return <tr
                                    onClick={() => {
                                        if (categoryList_setting_checked === false)
                                            DoNothing();
                                        else
                                            this.BulkEdit_SetSetting(SettingInput.CategoryList, option);
                                    }}
                                    style={categoryList_setting_checked ? { cursor: 'pointer' } : {}}
                                >
                                    <td width='42'>
                                        <input type='checkbox' className="form-check form-check-input" readOnly={true} style={{ margin: 0 }}
                                            checked={isChecked} disabled={categoryList_setting_checked === false}></input>
                                    </td>
                                    <td>{option.Label}</td>
                                </tr>;
                            })
                        }
                    </tbody></table>
                </div>
                <div className="select-checkbox">
                    <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, categoryList_setting_index)}>
                        <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : categoryList_setting_checked} readOnly={true} />
                    </div>
                </div>
            </div>);
        }

        //Active.
        const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        const active_setting_checked = setting_checked[active_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => {
                        if (active_setting_checked === false)
                            DoNothing();
                        else
                            this.BulkEdit_SetSetting(SettingInput.Active, e.currentTarget.checked);
                    }}
                    checked={CheckBoolean(setting[active_setting_index].value)}
                    readOnly={true}
                    disabled={active_setting_checked === false}
                    style={{ marginLeft: 7, cursor: 'pointer' }}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, active_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_property_index = Object.values(SettingInput).indexOf(property);
        const setting_index = property === SettingInput.CheckedItem ? 999 : setting_property_index;
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index) = ` + JSON.stringify(setting_index));
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            //CategoryList.
            case SettingInput.CategoryList:
                if (Array.isArray(setting[setting_index].value) === false)
                    setting[setting_index].value = [];
                const findIndex_category = setting[setting_index].value.findIndex(x => x.CategoryId === value.Id);
                if (findIndex_category < 0)
                    setting[setting_index].value.push({ CategoryId: value.Id, Name: value.Label });     //add.
                else
                    setting[setting_index].value.splice(findIndex_category, 1);     //remove.
                break;
            //Active.
            case SettingInput.Active:
                setting[setting_index].value = CheckBoolean(value);
                break;
            //CheckedItem.
            case SettingInput.CheckedItem:
                const checkedItem_setting_index = Object.values(SettingInput).indexOf(SettingInput.CheckedItem);
                const toggle = !setting_checked[value];
                setting[checkedItem_setting_index].value[value] = toggle;
                setting_checked[value] = toggle;
                if (toggle === false) {
                    //reset value(s).
                    switch (value) {
                        //CategoryList.
                        case Object.values(SettingInput).indexOf(SettingInput.CategoryList):
                            setting[value].value = []; break;
                        //Active.
                        case Object.values(SettingInput).indexOf(SettingInput.Active):
                            setting[value].value = false; break;
                        default: break;
                    }
                }
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (BulkEdit_Setting_checked) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (BulkEdit_Setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: SettingInput.CategoryList, value: [] },
                { key: SettingInput.Active, value: false },
                { key: SettingInput.CheckedItem, value: Object.keys(BulkSetting).map((data, key) => { return false; }) },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Category/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Category/BulkEdit/{Update|Remove}

        const setting_keys = Object.values(BulkSetting);
        let setting_params = [];
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i]) {
                    setting_params.push({
                        key: setting_keys[i],
                        value: this.state.BulkEdit_Setting[Object.values(SettingInput).indexOf(setting_keys[i])].value,
                    });
                }
                else {
                    setting_params.push({ key: setting_keys[i], value: null });
                }
            }
        }

        let organizerIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                organizerIds.push(CheckObjectNumber(list[n], 'Id'));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkOrganizerIds: organizerIds.join(','),

            CategoryList: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value,
            UpdateCategoryList: this.state.BulkEdit_Setting_checked[setting_keys.indexOf(BulkSetting.CategoryList)],

            Active: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Active)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi (response) =\n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');
                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `All selected Organizer(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} selected organizer(s).<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Organizer</h5>
                            {
                                Object.keys(DisplaySetting).map((setting, key) => {
                                    const values = Object.values(DisplaySetting);
                                    return (<div className="form-check">
                                        <input
                                            id={'formCheck_Toggle_' + setting}
                                            type='checkbox' className='form-check-input cursor-pointer'
                                            readOnly={true} checked={this.state.DisplaySettingOptions[key]}
                                            onClick={() => {
                                                let option = this.state.DisplaySettingOptions;
                                                option[key] = !option[key];
                                                this.setState({ DisplaySettingOptions: option });
                                                //unselect selected organizers if SchoolCode is checked.
                                                if (values[key] === DisplaySetting.SchoolCode && option[key]) {
                                                    const list = this.state.List;
                                                    let checkedItems = this.state.BulkEdit_CheckedItems;
                                                    for (let i = 0; i < checkedItems.length; i++) {
                                                        if (CheckObjectStringEmpty(list[i], 'SchoolCode') === '')
                                                            checkedItems[i] = false;
                                                    }
                                                    this.setState({ BulkEdit_CheckedItems: checkedItems });
                                                }
                                            }}
                                        ></input>
                                        <label className='form-check-label cursor-pointer' htmlFor={'formCheck_Toggle_' + setting}
                                            style={{ color: 'gray', fontSize: 'small', userSelect: 'none', }}>{values[key]}</label>
                                    </div>);
                                })
                            }
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                hidden={!this.state.PA_Update}
                            >Bulk Edit</Button>
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            {/* <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_SearchSchoolByParams()}
                            >Search School</Button> */}
                            <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_EditOrganizerModal(-1, true)}
                            >New Organizer</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-bordered table-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', width: 50, cursor: 'pointer' }}
                            onClick={() => {
                                if (this.state.DisplaySettingOptions.indexOf(true) < 0)
                                    this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0);
                            }}>
                            {
                                this.state.DisplaySettingOptions.indexOf(false) < 0 ? <span>#</span> :
                                    <input type='checkbox' className='pointer' readOnly={true} title='Select All'
                                        checked={this.state.BulkEdit_CheckedItems.length > 0 ? this.state.BulkEdit_CheckedItems.indexOf(false) < 0 : false}></input>
                            }
                            {/* <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <span>#</span>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <input type='checkbox' className="form-check form-check-input pointer" title='Select All' readOnly={true}
                                        checked={this.state.BulkEdit_CheckedItems.length > 0 ? this.state.BulkEdit_CheckedItems.indexOf(false) < 0 : false}></input>
                                </div>
                            </div> */}
                        </th>
                        <th style={{ textAlign: 'left', width: 0, }}>Organizer</th>
                        <th style={{ textAlign: 'left', }}>School Name</th>
                        <th style={{ textAlign: 'left', width: 350, }}>Other Info</th>
                        <th style={{ textAlign: 'center', width: 105, }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.ListComponents()}
                    {/* {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    } */}
                </tbody>
            </table>

            {/* Organizer - Edit / Create - Modal */}
            <Modal show={this.state.EditOrganizerModal_Toggle}
                onHide={() => {
                    if (this.state.EditOrganizer_Processing)
                        DoNothing()
                    else
                        this.Toggle_EditOrganizerModal()
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.EditOrganizerNew ? 'New' : 'Edit'} Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-buttons">
                        <Button
                            variant='primary'
                            onClick={() => this.Toggle_SearchSchoolByParams()}
                        >Search for School</Button>
                    </div>
                    {this.state.EditOrganizerNew ? null : <span>Id: {CheckObjectStringEmpty(this.state.EditOrganizerObject, 'Id')}</span>}
                    {this.EditOrganizerComponents()}
                    {/* {this.state.EditOrganizerNew || this.state.SelectedSchool !== null ? null : <label>Logo image file
                        <input
                            type="file"
                            onChange={this.onAttachPictureFile}
                            style={{ width: '100%' }}
                            name='imageLogo'
                            accept='image/png,image/jpeg'
                        />
                    </label>} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_EditOrganizerModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    {
                        this.state.EditOrganizerNew ?
                            <Button variant="primary" onClick={() => this.CreateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Create</Button>
                            :
                            <Button variant="primary" onClick={() => this.UpdateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Gov School - Search School(s) by Params - Modal */}
            <Modal
                show={this.state.SearchSchoolByParamsModal_Toggle}
                onHide={() => {
                    if (this.state.SearchSchoolByParams_Processing)
                        DoNothing();
                    else
                        this.Toggle_SearchSchoolByParams(true)
                }}
                centered
            >
                <Modal.Header closeButton={this.state.SearchSchoolByParams_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchSchoolByParams_Processing ? 'Searching...' : 'Search School(s)'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchSchoolByParams_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table className="table tb-no-border" cellPadding={5} cellSpacing={0} width='100%'>
                                <thead>
                                    <tr><th colSpan={2}><h5>Search By:</h5></th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="120px">School Code</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school code here)'
                                                onChange={(e) => this.setState({ SearchBySchoolCode: CheckStringEmpty(e.target.value) })}
                                                value={CheckStringEmpty(this.state.SearchBySchoolCode)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>School Name</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school name here)'
                                                onChange={(e) => this.setState({ SearchBySchoolName: CheckStringEmpty(e.target.value) })}
                                                value={CheckStringEmpty(this.state.SearchBySchoolName)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Country State</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter country state here)'
                                                onChange={(e) => this.setState({ SearchByCountryState: CheckStringEmpty(e.target.value) })}
                                                value={CheckStringEmpty(this.state.SearchByCountryState)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>District Area</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter district area here)'
                                                onChange={(e) => this.setState({ SearchByDistrictArea: CheckStringEmpty(e.target.value) })}
                                                value={CheckStringEmpty(this.state.SearchByDistrictArea)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchSchoolByParams_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_SearchSchoolByParams()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchGovernmentSchools_ViaAPI()}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Gov School - Search School(s) Result - Modal */}
            <Modal show={this.state.SearchSchoolResultModal_Toggle}
                onHide={() => this.Toggle_SeachSchoolResultModal()}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>School(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-bordered table-hover" cellPadding={10} cellSpacing={10} width='100%'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Country State</th>
                                <th>District Area</th>
                                <th style={{ textAlign: 'center', width: 100 }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.SchoolList.length === 0 ?
                                    <td colSpan={15} style={{ textAlign: 'center' }}>- no school is found - </td>
                                    :
                                    this.state.SchoolList.map((data, key) => {
                                        return (<tr className={this.state.SelectedSchool !== null && this.state.SelectedSchool === data ? 'tr-selected' : ''}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolCode')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolName')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'CountryState')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'DistrictArea')}</td>
                                            <td align="center"
                                                onClick={() => this.setState({
                                                    SelectedSchool: data,
                                                }, () => {
                                                    if (this.state.isDevMode)
                                                        console.log(`Selected School \n ${JSON.stringify(data)}`)
                                                })}
                                                style={{ cursor: 'pointer' }}>
                                                {/* {
                                                    this.state.SelectedSchool !== null && this.state.SelectedSchool === data ?
                                                        <Button
                                                            variant='secondary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: null,
                                                            })}
                                                        >Cancel</Button>
                                                        :
                                                        <Button
                                                            variant='primary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: data,
                                                            })}
                                                        >Select</Button>
                                                } */}
                                                {
                                                    this.state.SelectedSchool !== null && this.state.SelectedSchool === data ?
                                                        <input type='radio' name='r-school' id='r-school-1' value='1' checked={true} style={{ cursor: 'pointer' }} />
                                                        :
                                                        <input type='radio' name='r-school' id='r-school-2' value='2' checked={false} style={{ cursor: 'pointer' }} />
                                                }
                                            </td>
                                        </tr>);
                                    })
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_SeachSchoolResultModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.ConfirmSelectedSchoolResult()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Gov School - Search School(s) by Params - Modal */}
            <Modal
                show={this.state.EditOrganizerCategoryModal_Toggle}
                onHide={() => {
                    if (this.state.EditOrganizer_Processing)
                        DoNothing();
                    else
                        this.Toggle_EditOrganizerCategoryModal()
                }}
                centered
            >
                <Modal.Header closeButton={this.state.EditOrganizer_Processing === false}>
                    <Modal.Title>{CheckObjectStringEmpty(this.state.EditOrganizerObject, 'DisplayName') + ' (Category)'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.OrganizerCategoryComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_EditOrganizerCategoryModal()} disabled={this.state.EditOrganizer_Processing}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.UpdateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Update</Button>
                </Modal.Footer>
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button> */}
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.isUpdating}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >
        </div>);
    }
}