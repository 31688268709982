import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Select from "react-select";

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CapitalizeJsonKeys, CheckNullValue, CheckNumber, CheckObjectNumber, CheckObjectStringEmpty, Delay, DoNothing, FormatList_QuestionSet, GetPropIds, PagingComponents, UTCtoLocalDateTimeString, CheckStringEmpty } from "../utilities/GlobalFunctions";
import { GlobalSetting, LayoutScreen, PermissionAccessType } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import PreviewQuestionSetComponent from "./PreviewQuestionSetComponent";

const DataInput = {
    None: 0,
    Name: 1,
    Remark: 2,
    TotalQuestion: 3,
    IsPublic: 4,
    IsPrivateGroup: 5,
    Published: 6,
    DisplayOrder: 7,
    Group: 8,
    Subject: 9,
    SearchQsSet_ByGroup: 10,          //standard
    SearchQsSet_BySubject: 11,        //subject
    SearchQsSet_ByName: 12,           //name
    SearchQsSet_MaxQtyShow: 13,       //max result
};

export default class ManageQuestionSetPreviewScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.Ref_PreviewQuestionSetComponent = React.createRef();
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: true,
        PA_Teacher: false,   //2024.07.23

        //List.
        List: [],
        IsListLoaded: false,
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        //Search.
        ShowSearchQuestionSetModal: false,
        SearchQsSet_Processing: false,
        IsSearchQsSetConditionsValid: false,
        SearchQsSet_Result_List: [],
        //Select from Search Result.
        ShowSelectQuestionSetModal: false,
        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        //Search Options.
        SearchQsSet_ByGroup: null,          //standard
        SearchQsSet_BySubject: null,        //subject
        SearchQsSet_ByName: '',             //name
        SearchQsSet_MaxQtyShow: 5,          //max result

        // //Preview.
        // UniqueId: '',
        // QuestionSet: null,
        // QuestionSet_Components: null,
        // QuestionSet_Questions_Toggle: [],
        // PreviewQuestionSetModal_Toggle: false,
    });

    componentWillUnmount = async () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageQuestionSetPreview);
        await useAppService.getState().getGroups();
        await useAppService.getState().getSubjects();
        await Delay(0);
        this.LoadQuestionSetList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadQuestionSetList_ViaApi);
    }

    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.View, true),
            PA_Search: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Search, true),
            PA_Create: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageQuestionSetPreview, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageQuestionSetPreview_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            // OrganizerList: CheckObjectNullValue(gv.user, 'OrganizerInfoList', []),
        });
        await Delay(0);
    }

    //#region === Question Set List === start ===
    LoadQuestionSetList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();    //2024.07.24

        const fnName = 'LoadQuestionSetList_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const uid = CheckObjectStringEmpty(params, 'uid');
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');

        if (this.state.isDevMode)
            console.log(`${fnName} ${organizerId} ${authorId}`);

        let success = false;
        let messages = [];
        let list = [];
        let totalRows = 0;

        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageQuestionSetPreview_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
            });
        }

        this.setState({
            IsListLoaded: false,
            List: [],
            isLoading: true,
        });
        // await Delay(200);

        if (authorId > 0 && organizerId > 0) {
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/List/'
                + organizerId + '/'
                + authorId + '/'
                + 0 + '/'
                + 0 + '/'
                + this.state.PageIndex + '/'
                + this.state.PageSize,
                // Api/LearningCentre/Quiz/QuestionSet/List/{organizerId}/{authorId}/{groupId}/{subjectId}/{pageIndex}/{pageSize}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`${fnName} (source)`, JSON.stringify(data));
                    if (data.success) {
                        // success = true;
                        if (Array.isArray(data.data.list))
                            list = data.data.list;
                        totalRows = CheckObjectNumber(data.data, 'totalRows', list.length);
                    }
                    else {
                        if (CheckNullValue(data.message) !== null)
                            messages.push(data.message);
                        if (this.state.isDevMode)
                            console.log('Error', 'api - question set list (failed)\n' + JSON.stringify(data));
                    }
                    success = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - question set list (error)\n' + error.message);
                });

            if (list.length > 0) {
                let _List = []
                list.map((data, key) => {
                    return _List.push(CapitalizeJsonKeys(data));
                });
                list = this.PopulateQuestionSetData(_List);
                if (this.state.isDevMode)
                    console.log(`${fnName} (final)`, JSON.stringify(list));
            }

            if (!success)
                useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));
        }
        await Delay(200);
        this.setState({
            TotalRows: totalRows,
            List: list,
            IsListLoaded: true,
            isLoading: false,
        });
    }
    PopulateQuestionSetData = (list = null) => {
        let questionSetData = [];
        if (list !== null && Array.isArray(list)) {
            const groupOptions = useAppService.getState().groupOptions;
            const subjectOptions = useAppService.getState().subjectOptions;
            questionSetData = FormatList_QuestionSet(list, groupOptions, subjectOptions);
        }
        return questionSetData;
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];
            this.state.List.map((data, key) => {
                return listItems.push(
                    <tr key={'list-item-' + key}>
                        <td>{key + 1 + this.state.PageIndex}</td>
                        <td className="left" style={{ textAlign: 'justify' }}>{CheckObjectStringEmpty(data, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Group') === null ? '' : CheckObjectStringEmpty(data.Group, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Subject') === null ? '' : CheckObjectStringEmpty(data.Subject, 'Name')}</td>
                        <td>{CheckObjectNumber(data, 'TotalQuestion')}</td>
                        <td>{
                            CheckObjectStringEmpty(data, 'Remark') === '' ? 'N/A' :
                                <Button
                                    variant='outline-primary'
                                    onClick={() => useAppService.getState().setModal(CheckObjectStringEmpty(data, 'Name'),
                                        '<b><u>Remark :</u></b><br /><br /><span style="padding:20px">' + CheckObjectStringEmpty(data, 'Remark') + '</span>')}
                                >View</Button>
                        }</td>
                        <td>{UTCtoLocalDateTimeString(CheckObjectStringEmpty(data, 'CreatedOnUtc'))}</td>
                        <td style={{ padding: 5 }}>
                            <Button
                                variant='outline-primary'
                                onClick={() => this.Ref_PreviewQuestionSetComponent.current.TogglePreviewQuestionSetModal(CheckObjectStringEmpty(data, 'UniqueId'))}
                                style={{ width: '100%', }}
                            >Preview Question Set</Button>
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageQuestionSetPreview_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === Question Set List === end ===

    //#region === Question Set === Search === start ===
    Toggle_Search_QuestionSetModal = (resetCache = true) => {
        if (this.state.PA_Search === false)
            return null;
        this.setState({
            ShowSearchQuestionSetModal: !this.state.ShowSearchQuestionSetModal,
        }, () => {
            if (this.state.ShowSearchQuestionSetModal) {
                //reset
                this.setState({
                    SearchQsSet_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                    //Search Options.
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_ByName: '',                 //name
                    SearchQsSet_MaxQtyShow: 5,              //max result
                });
            }
            //reset by condition.
            if (resetCache) {
                this.setState({
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_ByName: '',                 //name
                    SearchQsSet_MaxQtyShow: 5,              //max result
                });
            }
            //check.
            this.Check_SearchQsSetCondition();
        });
    }
    Check_SearchQsSetCondition = () => {
        this.setState({
            IsSearchQsSetConditionsValid: Number(this.state.SearchQsSet_MaxQtyShow) <= 0 ? false : true,
        });
    }
    SearchQuestionSetByConditions = async () => {
        if (this.state.PA_Search === false)
            return null;

        this.setState({
            SearchQsSet_Processing: true,
            SearchQsSet_Result_List: [],
        });

        //query.
        let { success, list } = await this.SearchQuestionSet_ViaApi();
        if (success)
            list = FormatList_QuestionSet(list);
        else
            list = [];

        this.setState({
            SearchQsSet_Processing: false,
            SearchQsSet_Result_List: list,
        }, () => {
            this.Toggle_Search_QuestionSetModal(false);
            this.Toggle_Select_QuestionSetModal();
        });
    }
    SearchQuestionSet_ViaApi = async () => {
        if (this.state.PA_Search === false)
            return null;

        const fnName = 'SearchQuestionSet_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');
        const centerUserId = CheckObjectNumber(params, 'centerUserId');

        if (this.state.isDevMode)
            console.log(`${fnName} ${authorId} ${organizerId} ${centerUserId}`);

        let success = false;
        let messages = [];
        let list = [];

        if (authorId > 0 && organizerId > 0) {
            const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/SearchByName/'
                + organizerId + '/' + centerUserId + '/' + authorId + '/'
                + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
                + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
                + this.state.SearchQsSet_MaxQtyShow + '/'
                + CheckNullValue(this.state.SearchQsSet_ByName);
            if (this.state.isDevMode)
                console.log(url);

            await fetch(url,
                // Api/LearningCentre/QuizBank/QuestionSet/SearchByName/{organizerId}/{centerUserId}/{authorId}/{name}/{groupId}/{subjectId}/{maxQty}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`${fnName} ${JSON.stringify(data)}`);
                    if (data.success) {
                        success = true;
                        list = data.data;
                    }
                    else {
                        // messages.push('api search - question set - failed.\n' + JSON.stringify(data));
                        messages.push(data.message);
                    }
                })
                .catch(error => {
                    messages.push(error.message);
                });

            if (!success)
                useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

            if (list.length > 0) {
                list = CapitalizeJsonKeys(list);
            }
        }
        return { success, messages, list };
    }
    Toggle_Select_QuestionSetModal = () => {
        this.setState({
            ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
        }, () => {
            if (this.state.ShowSelectQuestionSetModal === false) {
                this.setState({
                    //reset
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_MaxQtyShow: 5,              //max result
                    SearchQsSet_ByName: '',

                    SearchQsSet_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                });
            }
        });
    }
    QuestionSetQueryResultListComponent = () => {
        let rows = [];
        //header.
        rows.push(<tr className='hide-row-hover' key={0}>
            <td width='50'></td>
            <td width='50'>#</td>
            <td className="left">Name</td>
            <td>Group</td>
            <td>Subject</td>
            <td width='135'>Total Question</td>
            <td>Remark</td>
            <td width='190'>Created Date</td>
        </tr>);
        //body.
        if (this.state.SearchQsSet_Result_List.length > 0) {
            this.state.SearchQsSet_Result_List.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={key + 1} style={{ cursor: 'pointer' }}>
                    <td>
                        <input type='radio' value={data.UniqueId} name='QSet'
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                            readOnly
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td className="left">{data.Name}</td>
                    <td>{data.Group.Name}</td>
                    <td>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td>{data.Remark}</td>
                    <td>{UTCtoLocalDateTimeString(data.CreatedOnUtc)}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={1}><td colSpan='8' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    SelectThisQuestionSet = (data) => {
        this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
        if (this.state.isDevMode)
            console.log('\nSelectThisQuestionSet = \n' + JSON.stringify(data));
    }
    SearchAgain_SelectQuestionSet = () => {
        this.Toggle_Select_QuestionSetModal();
        setTimeout(() => {
            this.Toggle_Search_QuestionSetModal(false);
        }, 300);
    }
    Confirm_SelectOnThisQuestionSet = () => {
        if (CheckNullValue(this.state.SearchQsSet_QuestionSet_Selected) !== null) {
            const uniqueId = CheckObjectStringEmpty(this.state.SearchQsSet_QuestionSet_Selected, 'UniqueId');
            if (uniqueId === '')
                useAppService.getState().setModal('Invalid Operation', 'Question Set not found.');
            else
                this.GotoEditQuestionSetPage(uniqueId);
        }
    }
    SaveSearchOptions = (value, inputType = DataInput.None) => {
        let selected = null;
        switch (inputType) {
            case DataInput.SearchQsSet_ByGroup:
                const groupOptions = useAppService.getState().groupOptions;
                const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                this.setState({ SearchQsSet_ByGroup: selected });
                break;
            case DataInput.SearchQsSet_BySubject:
                const subjectOptions = useAppService.getState().subjectOptions;
                const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                this.setState({ SearchQsSet_BySubject: selected });
                break;
            case DataInput.SearchQsSet_ByName:
                this.setState({ SearchQsSet_ByName: CheckStringEmpty(value) });
                break;
            case DataInput.SearchQsSet_MaxQtyShow:
                this.setState({ SearchQsSet_MaxQtyShow: CheckNumber(value, 5) });
                break;
            default: break;
        }
        this.Check_SearchQsSetCondition();
    }
    //#endregion === Question Set === Search === end ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Question Set (Preview)</h5>
                            <button
                                className="btn-link"
                                style={{ display: 'flex', padding: 0, textDecoration: 'none', height: '100%', alignItems: 'end', paddingBottom: 2 }}
                                onClick={() => this.LoadQuestionSetList_ViaApi()}
                                title='Refresh'
                            ><i className="fa fa-refresh"></i></button>
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ ShowSearchQuestionSetModal: true, SearchQsSet_Processing: false, SearchQuestionSet_Result: null, })}
                                hidden
                            >Search Question Set</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        <th width='135'>Group</th>
                        <th width='135'>Subject</th>
                        <th width='90'>Questions</th>
                        <th width='85'>Remark</th>
                        <th width='170'>Created Date</th>
                        <th width='205'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* QuestionSet - Search Question Set - Modal */}
            <Modal
                show={this.state.ShowSearchQuestionSetModal}
                onHide={() => this.state.SearchQsSet_Processing ? DoNothing() : this.Toggle_Search_QuestionSetModal()}
                centered
            >
                <Modal.Header closeButton={this.state.SearchQsSet_Processing === false}>
                    <Modal.Title>{this.state.SearchQsSet_Processing ? 'Searching...' : 'Search Question Set'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchQsSet_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <>
                                {/* <p>Search for Question Set:</p> */}
                                <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>
                                                <input type="text" className='form-control' style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_ByName}
                                                    onChange={(e) => this.SaveSearchOptions(e.target.value, DataInput.SearchQsSet_ByName)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Group</td>
                                            <td>
                                                <Select
                                                    options={useAppService.getState().groupOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_ByGroup !== null ?
                                                            this.state.SearchQsSet_ByGroup.label
                                                            : Locale("not-specify-group", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_ByGroup)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                <Select
                                                    options={useAppService.getState().subjectOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_BySubject !== null ?
                                                            this.state.SearchQsSet_BySubject.label
                                                            :
                                                            Locale("subject", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_BySubject)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max Result</td>
                                            <td>
                                                <input type="number" className="form-control" style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                    onChange={(e) => this.SaveSearchOptions(Number(e.target.value), DataInput.SearchQsSet_MaxQtyShow)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                    }
                </Modal.Body>
                {
                    !this.state.SearchQsSet_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchQuestionSetByConditions()} disabled={this.state.IsSearchQsSetConditionsValid === false}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* QuestionSet - Select Question Set - Modal */}
            <Modal
                size='xl'
                show={this.state.ShowSelectQuestionSetModal}
                onHide={() => this.Toggle_Select_QuestionSetModal()}
                centered
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Question Set from list below:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>Select a Question Set from list:</p> */}
                    <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                        style={{
                            border: '1px solid gray', marginTop: 0, marginBottom: 0,
                            borderTop: 'inset', borderBottomStyle: 'groove',
                        }}>
                        <tbody>
                            {this.QuestionSetQueryResultListComponent()}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.SearchAgain_SelectQuestionSet()}>Search Again</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.Confirm_SelectOnThisQuestionSet()} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>Select</Button>
                </Modal.Footer>
            </Modal>

            {/* Report - Preview Question Set - Modal */}
            {/* <Modal show={this.state.PreviewQuestionSetModal_Toggle} onHide={() => this.TogglePreviewQuestionSetModal()} centered>
                <Modal.Header closeButton><Modal.Title>Question Set (Preview)</Modal.Title></Modal.Header>
                <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.QuestionSet_Components }}></div></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.TogglePreviewQuestionSetModal()}>Close</Button>
                    <Button variant="primary" onClick={() => this.DownloadQuestionSetInPDF(this.state.UniqueId)}>Download Question Set (PDF)</Button>
                </Modal.Footer>
            </Modal>
            <div hidden={true}><div id='Div_Room_QuestionSet_hidden' dangerouslySetInnerHTML={{ __html: this.state.QuestionSet_Components }}></div></div> */}

            <PreviewQuestionSetComponent ref={this.Ref_PreviewQuestionSetComponent} />

        </div >);
    }
}
