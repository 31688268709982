import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import Select from "react-select";
import * as XLSX from "xlsx";
import { dbQuizBank } from "../utilities/Firebase";
import { off, onValue, ref, set } from "firebase/database";

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DoNothing, FormatList_QuestionSet, GetPropIds, PagingComponents, UTCtoLocalDateTimeString } from "../utilities/GlobalFunctions";
import { AtoZ, GlobalSetting, LayoutScreen, PermissionAccessType, UploadState, getMenuLink } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import PreviewQuestionSetComponent from "./PreviewQuestionSetComponent";    //2024.12.07
import { AlertMode } from "./AlertComponent";

const DataInput = {
    None: 0,
    Name: 1,
    Remark: 2,
    TotalQuestion: 3,
    IsPublic: 4,
    IsPrivateGroup: 5,
    Published: 6,
    DisplayOrder: 7,
    Group: 8,
    Subject: 9,
    SearchQsSet_ByGroup: 10,          //standard
    SearchQsSet_BySubject: 11,        //subject
    SearchQsSet_ByName: 12,           //name
    SearchQsSet_MaxQtyShow: 13,       //max result
};

const QuestionSetInitValue = {
    OrganizerId: 0,
    CenterUserId: 0,
    AuthorId: 0,
    AuthorName: '',
    AuthorUserId: 0,
    AuthorRoleId: 0,
    Name: '',
    Remark: '',
    UniqueId: '',
    TotalQuestion: 0,
    GroupId: 0,
    IsPublic: false,
    IsPrivateGroup: false,
    Published: true,
    DisplayOrder: 0,
    SubjectId: 0,
    Id: 0,
    Subject: null,
    Group: null,
    // Subject: {
    //     id: 0,
    //     name: '',
    //     label: ''
    // },
    // Group: {
    //     id: 0,
    //     name: '',
    //     label: ''
    // }
};

export default class ManageQuestionSetScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.Ref_PreviewQuestionSetComponent = React.createRef();   //2024.12.07
    }

    // dbQuizBank = getDatabase(app_rtdb_dbQuizBank);
    // unsubscribe_dbQuizBank = null;

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        PA_Teacher: false,   //2024.07.23

        //List.
        List: [],
        IsListLoaded: false,
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        //Search.
        ShowSearchQuestionSetModal: false,
        SearchQsSet_Processing: false,
        IsSearchQsSetConditionsValid: false,
        SearchQsSet_Result_List: [],
        //Select from Search Result.
        ShowSelectQuestionSetModal: false,
        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        //Search Options.
        SearchQsSet_ByGroup: null,          //standard
        SearchQsSet_BySubject: null,        //subject
        SearchQsSet_ByName: '',             //name
        SearchQsSet_MaxQtyShow: 5,          //max result

        //New or Edit.
        QuestionSetModal: null,
        Cached_QuestionSetModal: null,
        Mode_Create_QuestionSetModal: false,
        Show_CreateEditUpload_QuestionSetModal: false,

        //Delete.
        TargetQuestionSetModal: null,
        Show_Delete_QuestionSetModal: false,

        //Upload.
        Show_ProcessUploaded_QuestionSetModal: false,
        uploadedFile: null,
        FileToJson_Questions: null,
        FileToJson_Comprehension: null,
        FileToJson_FillInTheBlanks: null,
        QuestionSet_UniqueId: '',
        IsUploadConditionsFullfilled: false,
        UploadStatus: UploadState.None,
        UploadStatusText: '',

        //Upload to multiple organizers.
        Show_UploadForMultipleOrganizers_QuestionSetModal: false,
        SelectedOrganizers: [],
        OrganizerList: [],
        CategoryOptions: [],               //2024.12.07
        SelectedCategory: null,     //2024.12.07
    });

    componentWillUnmount = async () => {
        await this.Remove_ListenToUploadStatus();
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageQuestionSet);
        await useAppService.getState().getGroups();
        await useAppService.getState().getSubjects();
        await Delay(0);
        this.LoadQuestionSetList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadQuestionSetList_ViaApi);
    }

    //2024.07.24
    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            OrganizerList: CheckObjectNullValue(gv.user, 'OrganizerInfoList', []),
        });
        await Delay(0);
    }

    //#region === Question Set List === start ===
    LoadQuestionSetList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();    //2024.07.24

        const fnName = 'LoadQuestionSetList_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const uid = CheckObjectStringEmpty(params, 'uid');
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');

        if (this.state.isDevMode)
            console.log(`${fnName} ${organizerId} ${authorId}`);

        let success = false;
        let messages = [];
        let list = [];
        let totalRows = 0;
        let categoryOptions = [];      //2024.12.07

        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
            });
        }

        this.setState({
            IsListLoaded: false,
            List: [],
            isLoading: true,
        });
        // await Delay(200);

        if (authorId > 0 && organizerId > 0) {
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/List/'
                + organizerId + '/'
                + authorId + '/'
                + 0 + '/'
                + 0 + '/'
                + this.state.PageIndex + '/'
                + this.state.PageSize,
                // Api/LearningCentre/Quiz/QuestionSet/List/{organizerId}/{authorId}/{groupId}/{subjectId}/{pageIndex}/{pageSize}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`${fnName} (source)`, JSON.stringify(data));
                    if (data.success) {
                        // success = true;
                        if (Array.isArray(data.data.list))
                            list = data.data.list;
                        if (Array.isArray(data.data.categoryOptions))
                            categoryOptions = data.data.categoryOptions;
                        totalRows = CheckObjectNumber(data.data, 'totalRows', list.length);
                    }
                    else {
                        if (CheckNullValue(data.message) !== null)
                            messages.push(data.message);
                        if (this.state.isDevMode)
                            console.log('Error', 'api - question set list (failed)\n' + JSON.stringify(data));
                    }
                    success = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - question set list (error)\n' + error.message);
                });

            if (list.length > 0) {
                let _List = []
                list.map((data, key) => {
                    return _List.push(CapitalizeJsonKeys(data));
                });
                list = this.PopulateQuestionSetData(_List);
                if (this.state.isDevMode)
                    console.log(`${fnName} (final)`, JSON.stringify(list));
            }

            if (!success)
                useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));
        }
        await Delay(200);
        if (Array.isArray(categoryOptions) === false || categoryOptions.length === 0) {
            categoryOptions = [];
            // categoryOptions.push({ id: 0, label: 'All', value: 0 });
            // categoryOptions.push({ id: 1, label: 'Live Quiz', value: 1 });
            // categoryOptions.push({ id: 2, label: 'Kosa Kata', value: 2 });
        }
        else {
            let t_categoryOptions = [];
            t_categoryOptions.push({ id: 0, label: 'All', value: 0, modal: { id: 0, name: 'All', description: '', displayOrder: 0 } });
            categoryOptions.map((data, key) => {
                return t_categoryOptions.push(data);
            });
            categoryOptions = t_categoryOptions;
        }
        this.setState({
            TotalRows: totalRows,
            List: list,
            CategoryOptions: categoryOptions,                       //2024.12.07
            SelectedCategory: { id: 0, label: 'All', value: 0 },    //2024.12.07
            IsListLoaded: true,
            isLoading: false,
        });
    }
    PopulateQuestionSetData = (list = null) => {
        let questionSetData = [];
        if (list !== null && Array.isArray(list)) {
            const groupOptions = useAppService.getState().groupOptions;
            const subjectOptions = useAppService.getState().subjectOptions;
            questionSetData = FormatList_QuestionSet(list, groupOptions, subjectOptions);
        }
        return questionSetData;
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];
            this.state.List.map((data, key) => {
                return listItems.push(
                    <tr key={'list-item-' + key}>
                        <td>{key + 1 + this.state.PageIndex}</td>
                        {/* <td align='left'>
                            <Button
                                variant='primary'
                                onClick={() => this.GotoEditQuestionSetPage(CheckObjectStringEmpty(data, 'UniqueId'))}
                                style={{ textAlign: 'justify' }}
                            >{CheckObjectStringEmpty(data, 'Name')}</Button>
                        </td> */}
                        <td className="left" style={{ textAlign: 'justify' }}>{CheckObjectStringEmpty(data, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Group') === null ? '' : CheckObjectStringEmpty(data.Group, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Subject') === null ? '' : CheckObjectStringEmpty(data.Subject, 'Name')}</td>
                        <td>{CheckObjectNumber(data, 'TotalQuestion')}</td>
                        <td>{
                            CheckObjectStringEmpty(data, 'Remark') === '' ? 'N/A' :
                                <Button
                                    variant='outline-primary'
                                    onClick={() => useAppService.getState().setModal(CheckObjectStringEmpty(data, 'Name'),
                                        '<b><u>Remark :</u></b><br /><br /><span style="padding:20px">' + CheckObjectStringEmpty(data, 'Remark') + '</span>')}
                                >View</Button>
                        }</td>
                        <td>{UTCtoLocalDateTimeString(CheckObjectStringEmpty(data, 'CreatedOnUtc'))}</td>
                        <td style={{ padding: 5, display: 'flex', gap: 6, justifyContent: 'center' }}>
                            {
                                this.state.PA_View === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.Ref_PreviewQuestionSetComponent.current.TogglePreviewQuestionSetModal(CheckObjectStringEmpty(data, 'UniqueId'))}
                                    // style={{ width: '100%', }}
                                    >Preview</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.GotoEditQuestionSetPage(CheckObjectStringEmpty(data, 'UniqueId'))}
                                    // style={{ width: '100%', }}
                                    >Edit</Button>
                            }
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadQuestionSetList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === Question Set List === end ===

    //#region === Question Set === Search === start ===
    Toggle_Search_QuestionSetModal = (resetCache = true) => {
        if (this.state.PA_Search === false)
            return null;
        this.setState({
            ShowSearchQuestionSetModal: !this.state.ShowSearchQuestionSetModal,
        }, () => {
            if (this.state.ShowSearchQuestionSetModal) {
                //reset
                this.setState({
                    SearchQsSet_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                    //Search Options.
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_ByName: '',                 //name
                    SearchQsSet_MaxQtyShow: 5,              //max result
                });
            }
            //reset by condition.
            if (resetCache) {
                this.setState({
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_ByName: '',                 //name
                    SearchQsSet_MaxQtyShow: 5,              //max result
                });
            }
            //check.
            this.Check_SearchQsSetCondition();
        });
    }
    Check_SearchQsSetCondition = () => {
        this.setState({
            IsSearchQsSetConditionsValid: Number(this.state.SearchQsSet_MaxQtyShow) <= 0 ? false : true,
        });
    }
    SearchQuestionSetByConditions = async () => {
        if (this.state.PA_Search === false)
            return null;

        this.setState({
            SearchQsSet_Processing: true,
            SearchQsSet_Result_List: [],
        });

        //query.
        let { success, list } = await this.SearchQuestionSet_ViaApi();
        if (success)
            list = FormatList_QuestionSet(list);
        else
            list = [];

        this.setState({
            SearchQsSet_Processing: false,
            SearchQsSet_Result_List: list,
        }, () => {
            this.Toggle_Search_QuestionSetModal(false);
            this.Toggle_Select_QuestionSetModal();
        });
    }
    SearchQuestionSet_ViaApi = async () => {
        if (this.state.PA_Search === false)
            return null;

        const fnName = 'SearchQuestionSet_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');
        const centerUserId = CheckObjectNumber(params, 'centerUserId');

        if (this.state.isDevMode)
            console.log(`${fnName} ${authorId} ${organizerId} ${centerUserId}`);

        let success = false;
        let messages = [];
        let list = [];

        if (authorId > 0 && organizerId > 0) {
            const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/SearchByName/'
                + organizerId + '/' + centerUserId + '/' + authorId + '/'
                + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
                + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
                + this.state.SearchQsSet_MaxQtyShow + '/'
                + CheckNullValue(this.state.SearchQsSet_ByName);
            if (this.state.isDevMode)
                console.log(url);

            await fetch(url,
                // Api/LearningCentre/QuizBank/QuestionSet/SearchByName/{organizerId}/{centerUserId}/{authorId}/{name}/{groupId}/{subjectId}/{maxQty}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`${fnName} ${JSON.stringify(data)}`);
                    if (data.success) {
                        success = true;
                        list = data.data;
                    }
                    else {
                        // messages.push('api search - question set - failed.\n' + JSON.stringify(data));
                        messages.push(data.message);
                    }
                })
                .catch(error => {
                    messages.push(error.message);
                });

            if (!success)
                useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

            if (list.length > 0) {
                list = CapitalizeJsonKeys(list);
            }
        }
        return { success, messages, list };
    }
    Toggle_Select_QuestionSetModal = () => {
        this.setState({
            ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
        }, () => {
            if (this.state.ShowSelectQuestionSetModal === false) {
                this.setState({
                    //reset
                    SearchQsSet_ByGroup: null,              //standard
                    SearchQsSet_BySubject: null,            //subject
                    SearchQsSet_MaxQtyShow: 5,              //max result
                    SearchQsSet_ByName: '',

                    SearchQsSet_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                });
            }
        });
    }
    QuestionSetQueryResultListComponent = () => {
        let rows = [];
        //header.
        rows.push(<tr className='hide-row-hover' key={0}>
            <td width='50'></td>
            <td width='50'>#</td>
            <td className="left">Name</td>
            <td>Group</td>
            <td>Subject</td>
            <td width='135'>Total Question</td>
            <td>Remark</td>
            <td width='190'>Created Date</td>
        </tr>);
        //body.
        if (this.state.SearchQsSet_Result_List.length > 0) {
            this.state.SearchQsSet_Result_List.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={key + 1} style={{ cursor: 'pointer' }}>
                    <td>
                        <input type='radio' value={data.UniqueId} name='QSet'
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                            readOnly
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td className="left">{data.Name}</td>
                    <td>{data.Group.Name}</td>
                    <td>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td>{data.Remark}</td>
                    <td>{UTCtoLocalDateTimeString(data.CreatedOnUtc)}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={1}><td colSpan='8' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    SelectThisQuestionSet = (data) => {
        this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
        if (this.state.isDevMode)
            console.log('\nSelectThisQuestionSet = \n' + JSON.stringify(data));
    }
    SearchAgain_SelectQuestionSet = () => {
        this.Toggle_Select_QuestionSetModal();
        setTimeout(() => {
            this.Toggle_Search_QuestionSetModal(false);
        }, 300);
    }
    Confirm_SelectOnThisQuestionSet = () => {
        if (CheckNullValue(this.state.SearchQsSet_QuestionSet_Selected) !== null) {
            const uniqueId = CheckObjectStringEmpty(this.state.SearchQsSet_QuestionSet_Selected, 'UniqueId');
            if (uniqueId === '')
                useAppService.getState().setModal('Invalid Operation', 'Question Set not found.');
            else
                this.GotoEditQuestionSetPage(uniqueId);
        }
    }
    //#endregion === Question Set === Search === end ===

    //#region === Question Set === Delete === start ===
    DeleteThisQuestionSet = async () => {
        useAppService.getState().setModal('', '<b>Delete Question Set currently not available.</b>');
    }
    ToggleDeleteQuestionSetModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ Show_Delete_QuestionSetModal: !this.state.Show_Delete_QuestionSetModal });
    }
    //#endregion === Question Set === Delete === end ===

    //#region === Question Set === Create / New === start ===
    ToggleCreateQuestionSetModal = () => {
        if (this.state.PA_Create === false)
            return null;
        this.setState({
            Mode_Create_QuestionSetModal: !this.state.Mode_Create_QuestionSetModal
        }, () => {
            if (this.state.Mode_Create_QuestionSetModal) {
                this.setState({
                    QuestionSetModal: { ...QuestionSetInitValue },
                });
                this.ToggleCreateEditUploadQuestionSetModal();
                // this.InitQuestionSetModal();
            }
        });
    }
    //Reset.
    ResetCreateQuestionSetModal = () => {
        this.ToggleCreateQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateQuestionSetModal();
        }, 300);
    }
    //Template.
    CreateQuestionSetTemplate = () => {
        if (this.state.PA_Upload === false)
            return null;

        this.setState({
            UploadStatus: UploadState.Converting,
        });
        this.ToggleProcessUploadedQuestionSetModal();

        //Creating Template based on settings.
        let _qsModal = [];
        const totalQuestions = CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion');
        for (var i = 0; i < totalQuestions; i++) {
            _qsModal.push({
                QuizType: 'Objective',
                // No: (i + 1).toString(),
                Content: 'content',
                Answer: 'A',
                // Selection: 'A:-;B:-;C:-;D:-;',
                Tags: '',
                Hints: '',
                PictureUrl: '',
                A: '-',
                B: '-',
                C: '-',
                D: '-',
            });
        }

        //Continue.
        this.setState({
            FileToJson_Questions: _qsModal,
            FileToJson_Comprehension: null,
            FileToJson_FillInTheBlanks: null,
            UploadStatus: UploadState.Saving,
        }, () => {
            this.PopulatingJsonModalForFireStore();
            if (this.state.isDevMode)
                console.log(JSON.stringify(_qsModal));
        });
    }
    InitQuestionSetModal = () => {
        this.setState({
            // QuestionSetModal: { ...this.state.Cached_QuestionSetModal },
            QuestionSetModal: { ...QuestionSetInitValue },
            Cached_QuestionSetModal: null,
            uploadedFile: null,
            UploadStatus: UploadState.None,
            IsUploadConditionsFullfilled: false,
        });
    }
    //#endregion === Question Set === Create / New === end ===

    //#region === Question Set === Upload === start ===
    ToggleCreateEditUploadQuestionSetModal = () => {
        if (this.state.PA_Upload === false)
            return null;
        this.setState({
            Show_CreateEditUpload_QuestionSetModal: !this.state.Show_CreateEditUpload_QuestionSetModal,
        }, async () => {
            await Delay(200);
            if (this.state.Show_CreateEditUpload_QuestionSetModal === false) {
                if (this.state.Mode_Create_QuestionSetModal) {
                    this.setState({ Mode_Create_QuestionSetModal: !this.state.Mode_Create_QuestionSetModal, });
                }
            }
            this.InitQuestionSetModal();
        });
    }
    //Reset. Create/Upload.
    ResetUploadQuestionSetModal = () => {
        this.ToggleCreateEditUploadQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateEditUploadQuestionSetModal();
        }, 300);
    }
    //Reset. Create/Edit.
    ResetCreateEditQuestionSetModal = () => {
        this.ToggleCreateEditUploadQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateQuestionSetModal();
        }, 300);
    }
    // On file select (from the pop up)
    onUploadFileChange = (event) => {
        if (this.state.PA_Upload === false)
            return null;
        // Update the state
        this.setState({ uploadedFile: event.target.files[0], }, () => this.CheckOnUploadConditions());
    };
    CheckOnUploadConditions = () => {
        const questionSet = this.state.QuestionSetModal;
        let isValid = false;
        if (this.state.Mode_Create_QuestionSetModal) {
            //Create New.
            if (
                CheckObjectNumber(questionSet, 'TotalQuestion') > 0
                && CheckObjectStringEmpty(questionSet, 'Name') !== ''
                && CheckObjectNullValue(questionSet, 'Group') !== null
                && CheckObjectNullValue(questionSet, 'Subject')
            ) {
                isValid = true;
            }
        }
        else {
            //Create & Upload.
            if (
                CheckObjectNumber(questionSet, 'TotalQuestion') > 0
                && CheckObjectStringEmpty(questionSet, 'Name') !== ''
                && CheckObjectNullValue(questionSet, 'Group') !== null
                && CheckObjectNullValue(questionSet, 'Subject')
                && this.state.uploadedFile !== null
            ) {
                isValid = true;
            }
        }
        this.setState({ IsUploadConditionsFullfilled: isValid, });
        // console.log(
        //     'TotalQuestion set = ' + String(Number(this.state.NewQSet_TotalQuestion) > 0) +
        //     '\nName set = ' + String(this.state.NewQSet_Name !== '') +
        //     '\nGroup set = ' + String(this.state.NewQSet_Group !== null) +
        //     '\nFile set = ' + String(this.state.uploadedFile !== null) +
        //     '\nFullfilled = ' + String(_fullfilled)
        // );
    }
    //#endregion === Question Set === Upload === end ===

    //#region === Question Set === Process Uploaded === start ===
    ToggleProcessUploadedQuestionSetModal = () => {
        this.setState({ Show_ProcessUploaded_QuestionSetModal: !this.state.Show_ProcessUploaded_QuestionSetModal });
    }
    ProcessQuestionSetModal = async () => {

        if (this.state.uploadedFile === null || this.state.uploadedFile === undefined) {
            useAppService.getState().setModal('Upload Failed', 'Please select a question set before upload.');
            return null;
        }

        this.setState({
            QuestionSet_UniqueId: '',
            UploadStatus: UploadState.Validation,
        });
        this.ToggleProcessUploadedQuestionSetModal();
        let jsonModal_Questions = null;
        let jsonModal_Comprehension = null;
        let jsonModal_FillInTheBlanks = null;
        let reader = new FileReader();
        reader.onload = (event) => {
            /* Parse data */
            let bstr = event.target.result;
            let wb = XLSX.read(bstr, { type: "binary" });

            //=== All Questions === start ===//
            /* Get first worksheet */
            let wsname = wb.SheetNames[0];
            // console.log(wsname);
            if (wsname === 'Questions') {
                let ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let jsonData = XLSX.utils.sheet_to_json(ws);
                let jsonStrings = JSON.stringify(jsonData);
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                if (this.state.isDevMode)
                    console.log('Questions =\n' + jsonStrings);
                // jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null; });
                // jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return CheckNullValue(value) !== null ? value : null; });    //2023.08.30

                //2024.07.02
                jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => {

                    //2024.07.10
                    const findIndex = AtoZ.findIndex(x => x === String(key));
                    if (findIndex > -1) {
                        if (CheckNullValue(value) === '-')
                            return '-';
                    }

                    return CheckNullValue(value, '-') !== '-' ? value : null;
                });
                if (Array.isArray(jsonModal_Questions))
                    jsonModal_Questions = jsonModal_Questions.filter(x => x.Content !== null);

                if (this.state.isDevMode) {
                    console.log('Total Questions = ' + jsonModal_Questions.length);
                    console.log('Questions =\n' + JSON.stringify(jsonModal_Questions));
                }
            }
            //=== All Questions === end ===//

            //=== Comprehension, 2nd sheet === start ===//
            let wsname_comp = wb.SheetNames[1];
            // console.log(wsname_comp);
            if (wsname_comp === 'Comprehension') {
                let ws_comp = wb.Sheets[wsname_comp];
                /* Convert array of arrays */
                let jsonData_comp = XLSX.utils.sheet_to_json(ws_comp);
                let jsonStrings_comp = JSON.stringify(jsonData_comp);
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_comp = jsonStrings_comp.replaceAll('\\r\\n', '<br/>');
                // if (this.state.isDevMode)
                //     console.log('Comprehension =\n' + jsonStrings_comp);
                // jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return CheckNullValue(value) !== null ? value : null });    //2023.08.30
                // if (this.state.isDevMode)
                //     console.log('Comprehension =\n' + JSON.stringify(jsonModal_Comprehension));
            }
            //=== Comprehension, 2nd sheet === end ===//

            //2021.07.21
            //=== FillInTheBlanks, 3rd sheet === start ===//
            let wsname_fitb = wb.SheetNames[2];
            // console.log(wsname_fitb);
            if (wsname_fitb === 'FillInTheBlanks') {
                let ws_fitb = wb.Sheets[wsname_fitb];
                /* Convert array of arrays */
                let jsonData_fitb = XLSX.utils.sheet_to_json(ws_fitb);
                let jsonStrings_fitb = JSON.stringify(jsonData_fitb);
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_fitb = jsonStrings_fitb.replaceAll('\\r\\n', '<br/>');
                // if (this.state.isDevMode)
                //     console.log('FillInTheBlanks =\n' + jsonStrings_fitb);
                // jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return CheckNullValue(value) !== null ? value : null });  //2023.08.30
                // if (this.state.isDevMode)
                //     console.log('FillInTheBlanks =\n' + JSON.stringify(jsonModal_FillInTheBlanks));
            }
            //=== FillInTheBlanks, 3rd sheet === end ===//
        };
        reader.readAsArrayBuffer(this.state.uploadedFile);

        //2021.09.29 - moved to outer event func.
        await Delay(1000);
        if (jsonModal_Questions === null) {
            this.setState({
                //  fileConvertToJsonSuccess: false 
                UploadStatus: UploadState.ConvertFailed
            });
        }
        else {
            this.setState({
                FileToJson_Questions: jsonModal_Questions,
                // FileToJson_Comprehension: jsonModal_Comprehension,
                // FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks,
                FileToJson_Comprehension: jsonModal_Comprehension === null ? []
                    : jsonModal_Comprehension.length <= 0 ? []
                        : jsonModal_Comprehension[0].Id === null ? [] : jsonModal_Comprehension,
                FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks === null ? []
                    : jsonModal_FillInTheBlanks.length <= 0 ? []
                        : jsonModal_FillInTheBlanks[0].Id === null ? [] : jsonModal_FillInTheBlanks,
                // fileConvertToJsonSuccess: true
                // UploadStatus: UploadState.Uploading,
            }, () => {
                this.QuestionSetValidation(
                    this.state.FileToJson_Questions,
                    this.state.FileToJson_Comprehension,
                    this.state.FileToJson_FillInTheBlanks
                );
            });
        }
    }
    QuestionSetValidation = (questions, comprehension, fillInTheBlanks) => {
        // this.setState({
        //     UploadStatus: UploadState.Validation,
        // });

        let isValid = true;
        let messages = [];

        let hasComprehension = false;
        let hasFillInTheBlanks = false;

        let t_comprehensions = [];
        let t_fillInTheBlanks = [];

        if (this.state.isDevMode) {
            // console.log(this.state.uploadedFile !== null);
            console.log('\nquestions =\n' + JSON.stringify(questions));
            console.log('\ncomprehension =\n' + JSON.stringify(comprehension));
            console.log('\nfillInTheBlanks =\n' + JSON.stringify(fillInTheBlanks));
        }

        let _ignoreChecking = false;

        if (questions !== null) {
            //validating <Questions>.
            questions.map((data, key) => {
                //re-init.
                _ignoreChecking = false;

                //found <Comprehension>.
                if (data.QuizType === 'Comprehension') {      //  'Comprehension;13,16;4;Content_13_16;'
                    t_comprehensions.push(key + 1);
                    if (hasComprehension === false)
                        hasComprehension = true;
                }
                //found <FillInTheBlanks>.
                if (data.QuizType === 'FillInTheBlanks') {      //  'FillInTheBlanks;17,20;4;Content_17_20;'
                    t_fillInTheBlanks.push(key + 1);
                    if (hasFillInTheBlanks === false)
                        hasFillInTheBlanks = true;

                    if (fillInTheBlanks.length > 0) {
                        fillInTheBlanks.map((setData, setKey) => {
                            if (_ignoreChecking === false)
                                if (setData.Start >= data.Id || setData.End <= data.Id)
                                    _ignoreChecking = true;
                            return null;
                        });
                    }
                }

                //for QuizType = Objective/Comprehension/Subjective.
                if (_ignoreChecking === false) {
                    //check Content.
                    if (data.Content === undefined) {
                        isValid = false;
                        messages.push('undefined content for qs #' + (key + 1) + '.');
                    }
                    //check Answer.
                    if (data.Answer === undefined) {
                        isValid = false;
                        messages.push('undefined answer for qs #' + (key + 1) + '.');
                    }
                    //loop & check answer options' content for reserved symbol. (e.g. ":" & ";")
                    [
                        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
                        'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
                        'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                    ].map((opt, key) => {
                        if (data[opt] !== undefined) {
                            let text = String(data[opt]);
                            if (text.includes(':') || text.includes(';')) {
                                data[opt] = text.replaceAll(':', '：').replaceAll(';', '；');
                                // _messages.push('<b>invalid symbol found in answer option &lt;' + opt + '&gt; for qs #' + (key + 1) + '.</b>');
                            }
                        }
                        return null;
                    });
                }
                return null;
            });

            if (isValid) {
                //validating <Comprehension>.
                if (hasComprehension) {
                    if (comprehension !== null) {
                        if (comprehension.length > 0) {
                            comprehension.map((data, key) => {
                                if (comprehension.Passage === undefined) {
                                    isValid = false;
                                    messages.push('undefined passage in comprehension modal.');
                                }
                                if (t_comprehensions.findIndex(x => x === data.Start) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in comprehension modal.');
                                }
                                if (t_comprehensions.findIndex(x => x === data.End) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in comprehension modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        isValid = false;
                        messages.push('invalid comprehensions array conversion.');
                    }
                }
                //validating <FillInTheBlanks>.
                if (hasFillInTheBlanks) {
                    if (fillInTheBlanks !== null) {
                        if (fillInTheBlanks.length > 0) {
                            fillInTheBlanks.map((data, key) => {
                                if (t_fillInTheBlanks.findIndex(x => x === data.Start) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                if (t_fillInTheBlanks.findIndex(x => x === data.End) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        isValid = false;
                        messages.push('invalid fillInTheBlanks array conversion.');
                    }
                }
            }

            // if (this.state.isDevMode)
            //     console.log('isValid = ' + _isValid + '\nmessages = ' + _messages.join('\n'));
        }
        else {
            isValid = false;
            messages.push('invalid questions array conversion.');
        }
        if (isValid === false) {
            this.setState({
                UploadStatus: UploadState.ConvertFailed
            });
            useAppService.getState().setModal('File Validation Failed', messages.join('<br />'));
            this.ToggleProcessUploadedQuestionSetModal();
        }
        else {
            this.PopulatingJsonModalForFireStore();

            if (this.state.isDevMode)
                console.log('file conversion & validation success.\nisValid = ' + isValid + '\nerror messages = ' + messages.join('\n'));
        }
    }
    // ConvertToJson = (csv) => {
    //     var lines = csv.split("\n");
    //     var result = [];
    //     var headers = lines[0].split(",");
    //     for (var i = 1; i < lines.length; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //             obj[headers[j]] = currentline[j];
    //         }
    //         result.push(obj);
    //     }
    //     return result; //JavaScript object
    //     // return JSON.stringify(result); //JSON
    // }
    PopulatingJsonModalForFireStore = () => {
        let qsModal = [];
        //Convert to Keyed Json format.
        this.state.FileToJson_Questions.map((data, key) => {
            let _No = key + 1;
            qsModal.push({
                No: _No,
                Tags: CheckObjectStringEmpty(data, 'Tags'),
                QuizType: CheckObjectStringEmpty(data, 'QuizType'),
            });
            if (data.hasOwnProperty('QuizType')) {
                if (data.QuizType === 'Objective' || data.QuizType === 'Comprehension') {

                    qsModal[key].Content = CheckObjectStringEmpty(data, 'Content');
                    qsModal[key].Hints = CheckObjectStringEmpty(data, 'Hints');
                    qsModal[key].Answer = CheckObjectStringEmpty(data, 'Answer');
                    qsModal[key].PictureUrl = CheckObjectStringEmpty(data, 'PictureUrl');
                    qsModal[key].Selection = this.PopulateSelections(data);

                    if (data.QuizType === 'Comprehension') {
                        let _index = this.state.FileToJson_Comprehension.findIndex(x => _No >= x.Start && _No <= x.End);
                        if (_index > -1) {
                            let com = this.state.FileToJson_Comprehension[_index];
                            qsModal[key].SpecialMode = 'Comprehension;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                        }
                    }
                }
                else if (data.QuizType === 'FillInTheBlanks') {
                    let _index = this.state.FileToJson_FillInTheBlanks.findIndex(x => _No >= x.Start && _No <= x.End);
                    if (_index > -1) {
                        let com = this.state.FileToJson_FillInTheBlanks[_index];
                        if (com.Start === _No) {
                            qsModal[key].Content = data.hasOwnProperty('Content') ? data.Content : '';
                            qsModal[key].Hints = data.hasOwnProperty('Hints') ? CheckObjectStringEmpty(data, 'Hints') : '';
                            qsModal[key].Answer = data.hasOwnProperty('Answer') ? data.Answer : '';
                            qsModal[key].PictureUrl = data.hasOwnProperty('PictureUrl') ? data.PictureUrl : '';
                            qsModal[key].Selection = this.PopulateSelections(data);
                        }
                        qsModal[key].SpecialMode = 'FillInTheBlanks;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                    }
                }
            }
            return null;
        });
        this.SendQuestionSetModalToCMS_ViaApi(qsModal);
        // console.log(JSON.stringify(qsModal));
    }
    PopulateSelections = (array) => {
        let _selections = '';
        [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
            'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ].map((data, key) => {
            //2023.10.19
            if (CheckObjectNullValue(array, data) !== null)
                _selections += data + ':' + CheckObjectStringEmpty(array, data) + ';';
            return null;
        });
        return _selections;
    }
    SendQuestionSetModalToCMS_ViaApi = async (qsModal) => {

        // console.log('qsModal =\n' + JSON.stringify(qsModal));
        // this.ToggleProcessUploadedQuestionSetModal();
        // return null;

        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        this.setState({
            UploadStatus: UploadState.Uploading,
            UploadStatusText: '<span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span>',
        }, async () => {
            //set UploadStatus.
            set(ref(dbQuizBank, user.uid + '/UploadStatus'), this.state.UploadStatusText);
            // await dbQuizBank
            //     .ref(user.uid + '/UploadStatus')
            //     .set(this.state.UploadStatusText);
            await this.Remove_ListenToUploadStatus();
            this.ListenToUploadStatus();
        });
        await Delay(500);

        const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(user);
        if (this.state.isDevMode)
            console.log(`SendQuestionSetModalToCMS ${centerUserId}, ${authorId}, ${authorRoleId}, ${organizerId}`);

        let success = false;
        let messages = [];
        let apiResponse = null;

        const questionSet = this.state.QuestionSetModal;
        const group = CheckObjectNullValue(questionSet, 'Group') === null ? { id: 0, label: '', value: '' } : questionSet.Group;
        const subject = CheckObjectNullValue(questionSet, 'Subject') === null ? { id: 0, label: '', value: '' } : questionSet.Subject;
        const totalQuestion = CheckObjectNumber(questionSet, 'TotalQuestion');

        const jsonModel = {
            OrganizerId: organizerId,
            CenterUserId: centerUserId,
            AuthorId: authorId,
            AuthorRoleId: authorRoleId,     //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            Name: CheckObjectStringEmpty(questionSet, 'Name'),
            Remark: CheckObjectStringEmpty(questionSet, 'Remark'),
            TotalQuestion: Array.isArray(qsModal) && qsModal.length > totalQuestion ? qsModal.length : totalQuestion,

            GroupId: group.id,          //small letter id. key from GroupOptions
            GroupName: group.value,     //small letter value.

            SubjectId: subject.id,
            SubjectName: subject.value,

            IsPublic: CheckObjectBoolean(questionSet, 'IsPublic'),
            IsPrivateGroup: CheckObjectBoolean(questionSet, 'IsPrivateGroup'),
            DisplayOrder: CheckObjectNumber(questionSet, 'DisplayOrder'),
            Published: CheckObjectBoolean(questionSet, 'Published'),

            QuestionModel: qsModal,
            ComprehensionModel: this.state.FileToJson_Comprehension,
            FillInTheBlanksModel: this.state.FileToJson_FillInTheBlanks,

            //2024.05.09
            UploadToMultipleOrganizers: this.state.SelectedOrganizers.length > 0,
            OrganizerIds: this.state.SelectedOrganizers,
        };
        if (this.state.isDevMode)
            console.log('jsonModel =\n' + JSON.stringify(jsonModel));

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Upload',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    success = true;
                    apiResponse = data;
                }
                else {
                    messages.push(data.message);
                    if (this.state.isDevMode)
                        console.log('api upload - question set - failed.\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                messages.push(error.message);
                if (this.state.isDevMode)
                    console.log('Error : ' + error.message);
            });

        if (success === false) {
            this.setState({ UploadStatus: UploadState.Failed, UploadStatusText: messages, });
        }
        else {
            this.setState({
                UploadStatus: UploadState.Success,
                QuestionSet_UniqueId: CheckObjectStringEmpty(apiResponse, 'data'),
            });
        }
        await this.Remove_ListenToUploadStatus();
        if (this.state.isDevMode)
            console.log(messages.length > 0 ? messages : JSON.stringify(apiResponse));
    }
    UploadStatusMessage = () => {
        if (this.state.UploadStatus !== UploadState.None)
            switch (this.state.UploadStatus) {
                // case UploadState.Converting:
                //     return (<ProgressBar animated now={100} className='progressbar1' />);
                case UploadState.Validation:
                    return (<>
                        <span>Processing File Conversion & Validation...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Uploading:
                    return (<>
                        {/* <span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span> */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Saving:
                    return (<>
                        <span>Now Saving...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.ConvertFailed:
                    return (<span>File Conversion & Validation Failed.</span>);
                case UploadState.Success:
                    return (<>
                        {/* Upload Success. */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <span style={{ fontSize: 12, color: 'gray' }}>{this.state.QuestionSet_UniqueId}</span>
                    </>);
                case UploadState.Failed:
                    return (<span>Upload Failed.<br />{this.state.UploadStatusText}</span>);
                default:
                    return null;
            }
        return null;
    }
    ReloadQuestionSetList = () => {
        this.setState({
            List: [],
        }, () => {
            //close ui.
            this.ToggleProcessUploadedQuestionSetModal();
            // this.ToggleCreateEditUploadQuestionSetModal();
            // this.Toggle_QuestionSetModal_UploadForMultipleOrganizers();
            this.setState({
                Show_CreateEditUpload_QuestionSetModal: false,
                Mode_Create_QuestionSetModal: false,
                Show_UploadForMultipleOrganizers_QuestionSetModal: false,
            }, () => {
                this.InitQuestionSetModal();
            });

            // this.LoadQuestionSetList();                     //reload list.
            this.LoadQuestionSetList_ViaApi();
        });
    }
    ListenToUploadStatus = () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            onValue(ref(dbQuizBank, user.uid + '/UploadStatus'), (snapshot) => {
                // handle read data.
                if (snapshot.exists()) {
                    const data = CheckNullValue(snapshot.val());
                    if (data !== null) {
                        this.setState({ UploadStatusText: String(data), });
                    }
                    if (this.state.isDevMode)
                        console.log('Upload Status =\n' + String(data));
                }
            });

            // dbQuizBank.ref(user.uid + '/UploadStatus').on('value', snapshot => {
            //     // handle read data.
            //     if (snapshot.exists()) {
            //         const data = CheckNullValue(snapshot.val());
            //         if (data !== null) {
            //             this.setState({ UploadStatusText: String(data), });
            //         }
            //         if (this.state.isDevMode)
            //             console.log('Upload Status =\n' + String(data));
            //     }
            // });
        }
    }
    //2021.10.05
    Remove_ListenToUploadStatus = async () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            off(ref(dbQuizBank, user.uid + '/UploadStatus'));              //Off listener.
            set(ref(dbQuizBank, user.uid + '/UploadStatus'), null);        //Remove status text.

            // //Off listener.
            // await dbQuizBank.ref(user.uid + '/UploadStatus').off();
            // //Remove status text.
            // await dbQuizBank.ref(user.uid + '/UploadStatus').remove();
        }
    }
    //entry point appeared after upload is success.
    GotoEditQuestionSetPage = (_UniqueId = '') => {
        // this.props.TogglePage(this.props.Toggle.EditQuestionSet, _UniqueId);
        useAppService.getState().setQuestionSetUniqueId(_UniqueId);
        // this.setState({ redirectLink: CheckObjectStringEmpty(getMenu[LayoutScreen.EditQuestionSet], 'Link', '/'), redirect: true, });
        const link = getMenuLink(LayoutScreen.EditQuestionSet);
        // if (this.state.isDevMode)
        //     console.log('GotoEditQuestionSetPage = ' + link);
        this.setState({ redirectLink: link, redirect: true, });
    }
    //#endregion === Question Set === Process Uploaded === end ===

    //#region === Question Set === Edit === start ===
    SaveDataInput = (value, inputType = DataInput.None) => {
        let questionSet = this.state.QuestionSetModal;
        if (questionSet !== null) {
            let selected = null;
            switch (inputType) {
                case DataInput.Name:
                    // questionSet['Name'] = CheckStringEmpty(value);
                    questionSet['Name'] = CheckNullValue(value) === null ? '' : String(value);
                    break;
                case DataInput.Remark:
                    // value = CheckStringEmpty(value);
                    value = CheckNullValue(value) === null ? '' : String(value);
                    if (value.length <= 250)
                        questionSet['Remark'] = value;
                    break;
                case DataInput.TotalQuestion:
                    questionSet['TotalQuestion'] = CheckNumber(value);
                    break;
                case DataInput.DisplayOrder:
                    questionSet['DisplayOrder'] = CheckNumber(value);
                    break;
                case DataInput.IsPublic:
                    questionSet['IsPublic'] = CheckBoolean(value);
                    break;
                case DataInput.IsPrivateGroup:
                    questionSet['IsPrivateGroup'] = CheckBoolean(value);
                    break;
                case DataInput.Published:
                    questionSet['Published'] = CheckBoolean(value);
                    break;
                case DataInput.Group:
                    const groupOptions = useAppService.getState().groupOptions;
                    const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                    selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                    questionSet['Group'] = selected;
                    break;
                case DataInput.Subject:
                    const subjectOptions = useAppService.getState().subjectOptions;
                    const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                    selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                    questionSet['Subject'] = selected;
                    break;
                default: break;
            }
            if (this.state.isDevMode)
                console.log(`SaveDataInput ${Object.keys(DataInput)[inputType]} ${JSON.stringify(value)} \n ${JSON.stringify(this.state.QuestionSetModal)}`);

            this.setState({
                QuestionSetModal: questionSet,
            });
            this.CheckOnUploadConditions();
        }
    }
    SaveSearchOptions = (value, inputType = DataInput.None) => {
        let selected = null;
        switch (inputType) {
            case DataInput.SearchQsSet_ByGroup:
                const groupOptions = useAppService.getState().groupOptions;
                const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                this.setState({ SearchQsSet_ByGroup: selected });
                break;
            case DataInput.SearchQsSet_BySubject:
                const subjectOptions = useAppService.getState().subjectOptions;
                const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                this.setState({ SearchQsSet_BySubject: selected });
                break;
            case DataInput.SearchQsSet_ByName:
                this.setState({ SearchQsSet_ByName: CheckStringEmpty(value) });
                break;
            case DataInput.SearchQsSet_MaxQtyShow:
                this.setState({ SearchQsSet_MaxQtyShow: CheckNumber(value, 5) });
                break;
            default: break;
        }
        this.Check_SearchQsSetCondition();
    }
    //#endregion === Question Set === Edit === end ===

    //#region === Question Set === Upload For Multiple Organizers === start ===
    Toggle_QuestionSetModal_UploadForMultipleOrganizers = async () => {
        if (this.state.PA_Upload === false)
            return null;
        const toggle = !this.state.Show_UploadForMultipleOrganizers_QuestionSetModal;
        //2024.12.13
        if (toggle) {
            useAppService.getState().setModal('', 'fetching latest organizer(s)...', null, AlertMode.Loading);
            this.setState({ OrganizerList: [] });
            const list = await useAppService.getState().getOrganizerList();
            await Delay(0);
            if (Array.isArray(list) && list.length > 0)
                this.setState({ OrganizerList: list });
            await Delay(1000);
            useAppService.getState().setModal();
        }
        this.setState({
            Show_UploadForMultipleOrganizers_QuestionSetModal: toggle,
        }, async () => {
            // if (this.state.Show_UploadForMultipleOrganizers_QuestionSetModal)
            //     this.InitQuestionSetModal();
            // else
            // await this.Reset_QuestionSetModal_UploadForMultipleOrganizers();
        });
        await this.Reset_QuestionSetModal_UploadForMultipleOrganizers();
    }
    //2024.12.07
    OrganizerCategoryComponent = () => {
        let components = [];
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            const selectedCategory = this.state.SelectedCategory;
            components.push(<Select
                options={categoryOptions}
                placeholder={selectedCategory === null ? Locale("not-specify-category", this.state.locale) : selectedCategory.label}
                value={selectedCategory === null ? null : selectedCategory.value}
                onChange={(option) => this.setState({ SelectedCategory: option })}
            />);
        }
        return (components);
    }
    OrganizerListComponent = () => {
        let components = [];

        // const selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        // const organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        // const organizerList = CheckNullValue(this.state.OrganizerList, []);
        const selectedOrganizers = Array.isArray(this.state.SelectedOrganizers) ? this.state.SelectedOrganizers : [];
        const organizerList = Array.isArray(this.state.OrganizerList) ? this.state.OrganizerList : [];

        // console.log(JSON.stringify(selectedOrganizers));
        // console.log(JSON.stringify(organizerList));

        if (Array.isArray(selectedOrganizers) && Array.isArray(organizerList) && organizerList.length > 0) {

            const selectedCategory = CheckNullValue(this.state.SelectedCategory, { id: 0, label: 'All', value: 0 });

            //2024.12.07 - Select All.
            //#region === Select All ===
            // let organizerList = [];
            // const tmp_organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
            // if (Array.isArray(tmp_organizerList) && tmp_organizerList.length > 0)
            //     organizerList = tmp_organizerList;
            let isChecked_selectAll = false;
            if (selectedOrganizers.length === 0) {
                isChecked_selectAll = false;
            }
            else {
                if (selectedCategory.id === 0) {
                    isChecked_selectAll = organizerList.length > 0 ? selectedOrganizers.length === organizerList.length : false;
                }
                else {
                    let organizerWithSameCategory = [];
                    let selectedOrganizerWithSameCategory = [];
                    for (let o = 0; o < organizerList.length; o++) {
                        const organizerId = organizerList[o].OrganizerId;
                        const categoryOptions = organizerList[o].CategoryOptions;
                        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
                            if (categoryOptions.findIndex(x => x.id === selectedCategory.id) > -1)
                                organizerWithSameCategory.push(organizerId);
                            if (selectedOrganizers.findIndex(x => x === organizerId))
                                selectedOrganizerWithSameCategory.push(organizerId);
                        }
                    }
                    if (organizerWithSameCategory.length === 0)
                        isChecked_selectAll = false;
                    else
                        isChecked_selectAll = organizerWithSameCategory.length === selectedOrganizerWithSameCategory.length;
                }
            }
            // console.log(`OrganizerListComponent (isChecked_selectAll) = ${String(isChecked_selectAll)}`);
            // console.log(`OrganizerListComponent (selectedOrganizers.length) = ${String(selectedOrganizers.length)}`);
            // console.log(`OrganizerListComponent (organizerList.length) = ${String(organizerList.length)}`);
            const selectAll_disabled = selectedCategory.id > 0;
            components.push(<tr
                key={'s-org-itm-selectAll'}
                onClick={() => {
                    if (selectAll_disabled) { }
                    else {
                        let selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
                        if (Array.isArray(selectedOrganizers) === false)
                            selectedOrganizers = [];
                        if (isChecked_selectAll) {
                            //unselect all.
                            if (selectedCategory.id === 0) {
                                selectedOrganizers = [];
                            }
                            else {
                                //unselect all by selected category.
                                let cache_selectedOrganizers = JSON.parse(JSON.stringify(selectedOrganizers));
                                selectedOrganizers.map((data, key) => {
                                    const organizer = organizerList.find(x => x.OrganizerId === data);
                                    if (Array.isArray(organizer.CategoryList) && organizer.CategoryList.length > 0) {
                                        const findIndex = organizer.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id);
                                        if (findIndex > -1)
                                            cache_selectedOrganizers.splice(findIndex, 1);    //remove.
                                    }
                                    return null;
                                });
                                selectedOrganizers = cache_selectedOrganizers;
                            }
                        }
                        else {
                            //select all.
                            if (selectedCategory.id === 0) {
                                selectedOrganizers = organizerList.map((data, key) => { return CheckObjectNumber(data, 'OrganizerId'); });
                            }
                            else {
                                //select all by selected category.
                                let cache_selectedOrganizers = JSON.parse(JSON.stringify(selectedOrganizers));
                                organizerList.map((organizer, key) => {
                                    if (Array.isArray(organizer.CategoryList) && organizer.CategoryList.length > 0) {
                                        const findIndex = organizer.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id);
                                        const findIndex_selectedOrganizer = selectedOrganizers.findIndex(x => x === organizer.OrganizerId);
                                        if (findIndex > -1 && findIndex_selectedOrganizer < 0)
                                            cache_selectedOrganizers.push(CheckObjectNumber(organizer, 'OrganizerId'));     //add.
                                    }
                                    return null;
                                });
                                selectedOrganizers = cache_selectedOrganizers;
                            }
                            // selectedOrganizers = organizerList.map((data, key) => {
                            //     const isSameCategory = Array.isArray(data.CategoryList) && data.CategoryList.length > 0 ?
                            //         data.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1 : false;
                            //     if (selectedCategory.id === 0 || isSameCategory)
                            //         return CheckObjectNumber(data, 'OrganizerId');
                            // });
                        }
                        this.setState({
                            SelectedOrganizers: selectedOrganizers,
                        });
                    }
                }}
                style={selectAll_disabled ? {} : { cursor: 'pointer' }}
            >
                <td width='42'>
                    <input type="checkbox" className="form-check form-check-input"
                        checked={isChecked_selectAll}
                        readOnly={true}
                        style={{ margin: 0 }}
                        disabled={selectAll_disabled}
                    />
                </td>
                <td style={selectAll_disabled ? { color: 'gray' } : {}}>Select All</td>
            </tr>);
            //#endregion === Select All ===

            //option items.
            // let isSameCategory_count = 0;
            organizerList.map((data, key) => {
                const organizerId = CheckObjectNumber(data, 'OrganizerId');
                const isChecked = selectedOrganizers.findIndex(x => Number(x) === organizerId) > -1;
                const isSameCategory = Array.isArray(data.CategoryList) && data.CategoryList.length > 0 ?
                    data.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1 : false;
                // if (isSameCategory)
                //     isSameCategory_count++;
                return components.push(<tr
                    key={'s-org-itm-' + key}
                    onClick={() => this.UpdateSelectedOrganizers(key, !isChecked)}
                    style={{ cursor: 'pointer' }}
                    hidden={selectedCategory.id === 0 || isSameCategory ? false : true}
                >
                    <td width='42'>
                        <input type="checkbox" className="form-check form-check-input"
                            checked={isChecked}
                            readOnly={true}
                            style={{ margin: 0 }}
                        />
                    </td>
                    {/* <td>({organizerId}) {CheckObjectStringEmpty(data, 'DisplayName')}</td> */}
                    <td>{CheckObjectStringEmpty(data, 'DisplayName')}</td>
                    {/* <td>
                        {CheckObjectStringEmpty(data, 'DisplayName')}<br />
                        {String(selectedCategory.id === 0)}<br />
                        {String(isSameCategory)}
                    </td> */}
                </tr>);
            });
            // let selectAll_ele = document.getElementById('');
            // if (selectAll_ele !== null) {
            //     if (isSameCategory_count === 0)
            //         selectAll_ele.style.pointerEvents = 'none';
            //     else
            //         selectAll_ele.style.pointerEvents = 'revert';
            // }
        }

        return (components);
    }
    UpdateSelectedOrganizers = async (index = -1, isChecked = null) => {
        if (this.state.isDevMode)
            console.log('UpdateSelectedOrganizers = ' + String(index) + ' / ' + String(isChecked));

        if (index < 0 || isChecked === null)
            return null;

        const _isChecked = CheckBoolean(isChecked);

        // let selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        let selectedOrganizers = [];
        const tmp_selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        if (Array.isArray(tmp_selectedOrganizers) && tmp_selectedOrganizers.length > 0)
            selectedOrganizers = tmp_selectedOrganizers;

        // const organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        let organizerList = [];
        const tmp_organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        if (Array.isArray(tmp_organizerList) && tmp_organizerList.length > 0)
            organizerList = tmp_organizerList;

        if (Array.isArray(selectedOrganizers) && Array.isArray(organizerList) && index < organizerList.length) {
            const organizerId = CheckObjectNumber(organizerList[index], 'OrganizerId');
            const findIndex = selectedOrganizers.findIndex(x => Number(x) === organizerId);
            if (findIndex < 0) {
                //not found & checked.
                if (_isChecked)
                    selectedOrganizers.push(organizerId);
            }
            else {
                //found & not checked anymore.
                if (_isChecked === false)
                    selectedOrganizers.splice(findIndex, 1);
            }
        }
        selectedOrganizers.sort((a, b) => a - b);
        this.setState({
            SelectedOrganizers: selectedOrganizers,
        });
        if (this.state.isDevMode) {
            // console.log('UpdateSelectedOrganizers (OrganizerId) = \n' + CheckObjectNumber(organizerList[index], 'OrganizerId'));
            // console.log('UpdateSelectedOrganizers (organizerList) = \n' + JSON.stringify(organizerList));
            console.log('UpdateSelectedOrganizers (selectedOrganizers) = \n' + JSON.stringify(selectedOrganizers));
        }
        await Delay(0);
    }
    Reset_QuestionSetModal_UploadForMultipleOrganizers = async () => {
        this.setState({
            SelectedOrganizers: [],
        });
        this.InitQuestionSetModal();
        await Delay(0);
    }
    //#endregion === Question Set === Upload For Multiple Organizers === end ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Question Set</h5>
                            {
                                this.state.PA_Upload === false || this.state.PA_Teacher ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.Toggle_QuestionSetModal_UploadForMultipleOrganizers()}
                                    >Upload Question Set for Multiple Organizers</Button>
                            }
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ ShowSearchQuestionSetModal: true, SearchQsSet_Processing: false, SearchQuestionSet_Result: null, })}
                            >Search Question Set</Button>
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleCreateQuestionSetModal()}
                                    >Create</Button>
                            }
                            {
                                this.state.PA_Upload === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleCreateEditUploadQuestionSetModal()}
                                    >Upload Question Set</Button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        <th width='135'>Group</th>
                        <th width='135'>Subject</th>
                        <th width='90'>Questions</th>
                        <th width='85'>Remark</th>
                        <th width='170'>Created Date</th>
                        <th width='155'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* QuestionSet - Delete - Modal */}
            <Modal show={this.state.Show_Delete_QuestionSetModal} onHide={this.ToggleDeleteQuestionSetModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Question Set Code: <b>{CheckObjectStringEmpty(this.state.TargetQuestionSetModal, 'QuestionSetCode')}</b>
                        <br />Are you sure you want to <b>delete</b> this question set ?
                        <br />The deletion is not reversible.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteQuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.DeleteThisQuestionSet()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Create / Edit / Upload - Modal */}
            <Modal size='lg' show={this.state.Show_CreateEditUpload_QuestionSetModal} onHide={this.ToggleCreateEditUploadQuestionSetModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create {this.state.Mode_Create_QuestionSetModal ? 'New' : '& Upload'} Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td colSpan='3'>
                                    <table cellPadding='5' width='100%' border='0'>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                                <td style={{ width: '50%' }}>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Name')}
                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                                    />
                                                </td>
                                                <td style={{ width: '35%', verticalAlign: 'top', paddingRight: '30px' }} rowSpan='2'>
                                                    <table cellPadding='5' width='100%' border='0'>
                                                        <tbody>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Is Public</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPublic')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Is Private Group</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPrivateGroup')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Published</td>
                                                                <td style={{ width: '25%', }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'Published')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Display Order</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="number" className="form-control" style={{ width: '100%' }}
                                                                        defaultValue={CheckObjectNumber(this.state.QuestionSetModal, 'DisplayOrder')}
                                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Total Question *</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="number" className="form-control" style={{ width: '100%' }}
                                                                        defaultValue={CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion')}
                                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark</td>
                                                <td style={{ width: '35%' }}>
                                                    <textarea
                                                        ref={this.NQSet_Remark}
                                                        rows="4" cols="50"
                                                        className="form-control"
                                                        placeholder={'...remark...'}
                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                                    <Row>
                                                        <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                        <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length + '/250 charactors remaining.)'}</Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                                <td style={{ width: '35%' }}>
                                                    <Select
                                                        options={useAppService.getState().groupOptions}
                                                        placeholder={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Group') !== null ?
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Group, 'label')
                                                                : Locale("not-specify-group", this.state.locale)
                                                        }
                                                        value={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ? null :
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Group, 'value')
                                                        }
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                                <td style={{ width: '35%' }}>
                                                    <Select
                                                        options={useAppService.getState().subjectOptions}
                                                        placeholder={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') !== null ?
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Subject, 'label')
                                                                : Locale("not-specify-subject", this.state.locale)
                                                        }
                                                        value={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ? null :
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Subject, 'value')
                                                        }
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {
                                this.state.Mode_Create_QuestionSetModal ? null :
                                    <>
                                        <tr><td colSpan='3'><hr /></td></tr>
                                        <tr>
                                            <td colSpan='3'>
                                                <button
                                                    className='link-button'
                                                    onClick={() => {
                                                        let link40 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 40 + ".xlsx', '_new')";
                                                        let link100 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 100 + ".xlsx', '_new')";
                                                        let linkSample = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_SAMPLE.xlsx', '_new')";
                                                        let htmlText = '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Question Amount</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td>40</td><td><button class="link-button" onClick="' + link40 + '">download</button></td></tr>';
                                                        htmlText += '<tr><td>100</td><td><button class="link-button" onClick="' + link100 + '">download</button></td></tr>';
                                                        htmlText += '</tbody></table><br />';
                                                        htmlText += '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                                        htmlText += '</tbody></table>';
                                                        htmlText += '<br /><ul style="list-style-type: circle"><li>for other question amounts, please download any file, then fill-in or remove the remaining questions if needed.</li></ul>'
                                                        useAppService.getState().setModal('Question Sets Template', htmlText);
                                                    }}
                                                >Download & use the provided spreadsheet template file</button> for upload purpose.
                                                <p>
                                                    Using other files with different column name or format may cause errors on data verification on the questions, or may further create unforseen bugs that may greatly affecting the usability or accessability of the quiz system on your account.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }}
                                                    disabled={this.state.PA_Upload === false} />*
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;
                                                <span>Continue to upload this file ?</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleCreateEditUploadQuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.state.Mode_Create_QuestionSetModal ? this.ResetCreateEditQuestionSetModal() : this.ResetUploadQuestionSetModal()}>Reset</Button>
                    &nbsp;&nbsp;
                    {/* <Button variant="primary" onClick={this.UploadNewQuestionSet}>Upload</Button> */}
                    <Button
                        variant="primary"
                        onClick={() => this.state.Mode_Create_QuestionSetModal ? this.CreateQuestionSetTemplate() : this.ProcessQuestionSetModal()}
                        disabled={this.state.IsUploadConditionsFullfilled === false || this.state.PA_Upload === false || this.state.PA_Create === false}
                    >{this.state.Mode_Create_QuestionSetModal ? 'Create' : 'Upload'}</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Process Uploaded - Modal */}
            <Modal show={this.state.Show_ProcessUploaded_QuestionSetModal}
                onHide={
                    this.state.UploadStatus === UploadState.ConvertFailed ||
                        this.state.UploadStatus === UploadState.Success ||
                        this.state.UploadStatus === UploadState.Failed
                        ? this.ToggleProcessUploadedQuestionSetModal : DoNothing
                }
                centered
                backdrop='static'
                keyboard='false'
                dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header
                // closeButton={
                //     this.state.UploadStatus === UploadState.Success ||
                //     this.state.UploadStatus === UploadState.Failed
                // }
                >
                    <Modal.Title style={{ fontSize: 20 }}>{
                        this.state.UploadStatus === UploadState.ConvertFailed ||
                            this.state.UploadStatus === UploadState.Success ||
                            this.state.UploadStatus === UploadState.Failed
                            ? 'Result' : 'Processing...'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.UploadStatusMessage()}
                </Modal.Body>
                <Modal.Footer
                    hidden={
                        this.state.UploadStatus === UploadState.ConvertFailed ||
                            this.state.UploadStatus === UploadState.Success ||
                            this.state.UploadStatus === UploadState.Failed ? false : true
                    }>
                    {/* <Button variant="secondary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Close</Button> */}
                    {/* &nbsp;&nbsp;
                        <Button variant="primary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Confirm</Button> */}
                    {/* {
                                this.state.UploadStatus === UploadState.ConvertFailed ||
                                    this.state.UploadStatus === UploadState.Success ||
                                    this.state.UploadStatus === UploadState.Failed
                                    ?
                                    <> */}
                    <Button variant="secondary" onClick={() => this.ReloadQuestionSetList()}>Close</Button>&nbsp;&nbsp;
                    <Button
                        variant="primary"
                        onClick={() => this.GotoEditQuestionSetPage(this.state.QuestionSet_UniqueId)}
                        disabled={this.state.QuestionSet_UniqueId === ''}
                        hidden={
                            this.state.SelectedOrganizers.length > 0 ||
                                this.state.UploadStatus === UploadState.ConvertFailed ||
                                this.state.UploadStatus === UploadState.Failed ? true : false
                        }
                    >View & Edit</Button>
                    {/* </>
                                    : null
                            } */}
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Search Question Set - Modal */}
            <Modal
                show={this.state.ShowSearchQuestionSetModal}
                onHide={() => this.state.SearchQsSet_Processing ? DoNothing() : this.Toggle_Search_QuestionSetModal()}
                centered
            >
                <Modal.Header closeButton={this.state.SearchQsSet_Processing === false}>
                    <Modal.Title>{this.state.SearchQsSet_Processing ? 'Searching...' : 'Search Question Set'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchQsSet_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <>
                                {/* <p>Search for Question Set:</p> */}
                                <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>
                                                <input type="text" className='form-control' style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_ByName}
                                                    onChange={(e) => this.SaveSearchOptions(e.target.value, DataInput.SearchQsSet_ByName)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Group</td>
                                            <td>
                                                <Select
                                                    options={useAppService.getState().groupOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_ByGroup !== null ?
                                                            this.state.SearchQsSet_ByGroup.label
                                                            : Locale("not-specify-group", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_ByGroup)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                <Select
                                                    options={useAppService.getState().subjectOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_BySubject !== null ?
                                                            this.state.SearchQsSet_BySubject.label
                                                            :
                                                            Locale("subject", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_BySubject)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max Result</td>
                                            <td>
                                                <input type="number" className="form-control" style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                    onChange={(e) => this.SaveSearchOptions(Number(e.target.value), DataInput.SearchQsSet_MaxQtyShow)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                    }
                </Modal.Body>
                {
                    !this.state.SearchQsSet_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchQuestionSetByConditions()} disabled={this.state.IsSearchQsSetConditionsValid === false}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* QuestionSet - Select Question Set - Modal */}
            <Modal
                size='xl'
                show={this.state.ShowSelectQuestionSetModal}
                onHide={() => this.Toggle_Select_QuestionSetModal()}
                centered
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Question Set from list below:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>Select a Question Set from list:</p> */}
                    <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                        style={{
                            border: '1px solid gray', marginTop: 0, marginBottom: 0,
                            borderTop: 'inset', borderBottomStyle: 'groove',
                        }}>
                        <tbody>
                            {this.QuestionSetQueryResultListComponent()}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.SearchAgain_SelectQuestionSet()}>Search Again</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.Confirm_SelectOnThisQuestionSet()} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>Select</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Upload for Multiple Organizers - Modal */}
            <Modal size='xl' show={this.state.Show_UploadForMultipleOrganizers_QuestionSetModal} onHide={this.Toggle_QuestionSetModal_UploadForMultipleOrganizers} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Question Set for Multiple Organizers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td colSpan='3'>
                                    <div style={{ display: 'flex', gap: 15, }}>
                                        <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, }}>
                                            <table cellPadding='5' width='100%' border='0' style={{ height: 'fit-content', }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={15} style={{ textAlign: 'center' }} > Question Set (Setting)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="text" className="form-control" style={{ width: '100%' }}
                                                                defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Name')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark</td>
                                                        <td style={{ width: '35%' }}>
                                                            <textarea
                                                                ref={this.NQSet_Remark}
                                                                rows="4" cols="50"
                                                                className="form-control"
                                                                placeholder={'...remark...'}
                                                                defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                                            <Row>
                                                                <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                                <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length + '/250 charactors remaining.)'}</Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <Select
                                                                options={useAppService.getState().groupOptions}
                                                                placeholder={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ?
                                                                        Locale("not-specify-group", this.state.locale)
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Group'], 'label')
                                                                }
                                                                value={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ?
                                                                        null
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Group'], 'value')
                                                                }
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <Select
                                                                options={useAppService.getState().subjectOptions}
                                                                placeholder={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ?
                                                                        Locale("not-specify-subject", this.state.locale)
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Subject'], 'label')
                                                                }
                                                                value={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ?
                                                                        null
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Subject'], 'value')
                                                                }
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                                        </td>
                                                    </tr>

                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Is Public</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPublic')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Is Private Group</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPrivateGroup')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Published</td>
                                                        <td style={{ width: '35%', }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'Published')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Display Order</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="number" className="form-control" style={{ width: '100%' }}
                                                                value={CheckObjectNumber(this.state.QuestionSetModal, 'DisplayOrder')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Total Question *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="number" className="form-control" style={{ width: '100%' }}
                                                                value={CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 15, width: '50%' }}>
                                            {
                                                Array.isArray(this.state.CategoryOptions) && this.state.CategoryOptions.length > 0 ?
                                                    <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, }}>
                                                        <table className="table table-hover no-border-style" cellPadding='5' width='100%' border='0' style={{ margin: 0, borderStyle: 'hidden' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ paddingLeft: 50 }}>Category</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{this.OrganizerCategoryComponent()}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : null
                                            }
                                            <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, height: 450, overflowY: 'scroll' }}>
                                                <table className="table table-hover table-bordered no-border-style" cellPadding='5' width='100%' border='0' style={{ borderTopStyle: 'hidden' }}>
                                                    <thead>
                                                        <tr>
                                                            <th width='0px'></th>
                                                            <th>Organizer(s)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.OrganizerListComponent()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {
                                this.state.Mode_Create_QuestionSetModal ? null :
                                    <>
                                        <tr><td colSpan='3'><hr /></td></tr>
                                        <tr>
                                            <td colSpan='3'>
                                                <button
                                                    className='link-button'
                                                    onClick={() => {
                                                        let link40 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 40 + ".xlsx', '_new')";
                                                        let link100 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_" + 100 + ".xlsx', '_new')";
                                                        let linkSample = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_SAMPLE.xlsx', '_new')";
                                                        let htmlText = '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Question Amount</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td>40</td><td><button class="link-button" onClick="' + link40 + '">download</button></td></tr>';
                                                        htmlText += '<tr><td>100</td><td><button class="link-button" onClick="' + link100 + '">download</button></td></tr>';
                                                        htmlText += '</tbody></table><br />';
                                                        htmlText += '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                                        htmlText += '</tbody></table>';
                                                        htmlText += '<br /><ul style="list-style-type: circle"><li>for other question amounts, please download any file, then fill-in or remove the remaining questions if needed.</li></ul>'
                                                        useAppService.getState().setModal('Question Sets Template', htmlText);
                                                    }}
                                                >Download & use the provided spreadsheet template file</button> for upload purpose.
                                                <p>
                                                    Using other files with different column name or format may cause errors on data verification on the questions, or may further create unforseen bugs that may greatly affecting the usability or accessability of the quiz system on your account.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }}
                                                    disabled={this.state.PA_Upload === false} />*
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;
                                                <span>Continue to upload this file ?</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ gap: 5 }}>
                    <Button variant="secondary" onClick={() => this.Toggle_QuestionSetModal_UploadForMultipleOrganizers()}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.Reset_QuestionSetModal_UploadForMultipleOrganizers()}>Clear</Button>
                    <Button
                        variant="primary"
                        // onClick={() => this.Process_QuestionSetModal_UploadForMultipleOrganizers()}
                        onClick={() => this.ProcessQuestionSetModal()}
                        disabled={
                            this.state.IsUploadConditionsFullfilled === false
                            || this.state.PA_Upload === false
                            || this.state.PA_Create === false
                            || this.state.SelectedOrganizers.length === 0
                        }
                    >Upload</Button>
                </Modal.Footer>
            </Modal>

            <PreviewQuestionSetComponent ref={this.Ref_PreviewQuestionSetComponent} />
        </div >);
    }
}
