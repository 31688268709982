import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";

import { CommonState, GlobalSetting, LayoutScreen } from "../utilities/GlobalSetting";
import { useAppService } from "../services/AppService";
import { useGlobal } from "../utilities/GlobalVariables";
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNumber, CheckObjectStringEmpty, CommonStatusMessage, Delay, DelayUntil, GetPropIds, PagingComponents } from "../utilities/GlobalFunctions";
import { AlertMode } from "./AlertComponent";

const ItemProperty = {
    None: 'none',
    Id: 'Id',
    Name: 'Name',
    Description: 'Description',
    DisplayOrder: 'DisplayOrder',
    Active: 'Active',
};

//Jay created 2024.12.10+
export default class ManageOrganizerCategoryScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        //List.
        List: [],
        IsListLoaded: false,
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        //New / Edit.
        EditUiModal_Toggle: false,
        EditModalState: CommonState.None,
        CommonStatus: CommonState.None,
        TargetModalIndex: -1,
        TargetModal: null,
        TargetModal_Cached: null,

        //delete.
        DeleteTargetModal_Toggle: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.None);
        this.LoadList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === Category List ===
    LoadList_ViaApi = async (newSearch = false) => {

        this.setState({
            isLoading: true,
            List: [],
            IsListLoaded: false,
            PageIndex: newSearch ? 0 : this.state.PageIndex,
        });
        await Delay(0);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = `${GlobalSetting.ApiUrl}Api/LearningCentre/Organizer/Category/List/${organizerId}/${authorId}/${null}`;

        if (this.state.isDevMode)
            console.log('LoadList_ViaApi', url);

        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data)) {
                            _List = CapitalizeJsonKeys(data.data);
                            totalRows = _List.length;
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log('List is empty.');
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - category - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - category - load list (error)\n' + error.message);
            });

        if (Array.isArray(_List) && _List.length > 0) {
            _List = _List.map((data, key) => { return this.PopulateCategoryItem(data); });
            _List.sort((a, b) => a.Id - b.Id);
        }
        this.setState({
            isLoading: false,
            List: _List,
            TotalRows: totalRows,
            IsListLoaded: true,
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    PopulateCategoryItem = (item = null) => {
        return {
            Id: CheckObjectNumber(item, 'Id'),
            Name: CheckObjectStringEmpty(item, 'Name'),
            Description: CheckObjectStringEmpty(item, 'Description'),
            DisplayOrder: CheckObjectNumber(item, 'DisplayOrder'),
            Active: CheckObjectBoolean(item, 'Active'),
        };
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={this.state.TableColumn} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            return components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                <td className='left'>{CheckObjectStringEmpty(data, 'Description', '-')}</td>
                <td>{CheckObjectNumber(data, 'DisplayOrder', 0)}</td>
                <td><input type="checkbox" className="form-check form-check-input" readOnly={true} checked={CheckObjectBoolean(data, 'Active')} disabled={true} /></td>
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditModal(key)}
                    >Edit</button>
                </td>
            </tr>);
        });

        return (components);
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageOrganizerCategory_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === Category List ===

    //#region === New / Update ===

    ToggleEditModal = async (index = -1, forceClose = false) => {
        const toggle = !this.state.EditUiModal_Toggle;
        if (forceClose || !toggle) {
            this.setState({ EditUiModal_Toggle: false, });
            await Delay(200);
            this.setState({
                TargetModalIndex: -1,
                TargetModal: null,
                TargetModal_Cached: null,
                EditModalState: CommonState.None,
            });
            return null;
        }
        const target = index < 0 ?
            this.PopulateCategoryItem(this.state.TargetModal)
            : this.PopulateCategoryItem(this.state.List[index]);
        this.setState({
            TargetModalIndex: index,
            TargetModal: target,
            TargetModal_Cached: this.PopulateCategoryItem(target),
            EditModalState: index < 0 ? CommonState.New : CommonState.Edit,
        });
        await Delay(200);
        this.setState({ EditUiModal_Toggle: true, });
    }
    EditModalComponents = () => {
        let components = [];
        const target = this.state.TargetModal;
        if (target !== null) {

            //Name.
            components.push(<div key='category-Name' className="form-group">
                <label>Name</label>
                <input
                    name="Name" className="form-control" type="text"
                    value={target[ItemProperty.Name]}
                    placeholder={CheckObjectStringEmpty(target, ItemProperty.Name, ItemProperty.Name)}
                    onChange={(e) => this.SetModalValue(ItemProperty.Name, e.target.value)}
                />
            </div>);

            //Description.
            components.push(<div key='category-Description' className="form-group">
                <label>Description</label>
                <input
                    name="Name" className="form-control" type="text"
                    value={target[ItemProperty.Description]}
                    placeholder={CheckObjectStringEmpty(target, ItemProperty.Description, ItemProperty.Description)}
                    onChange={(e) => this.SetModalValue(ItemProperty.Description, e.target.value)}
                />
            </div>);

            //DisplayOrder.
            components.push(<div key='category-DisplayOrder' className="form-group">
                <label>Display Order</label>
                <input
                    name="Name" className="form-control" type="number"
                    value={target[ItemProperty.DisplayOrder]}
                    placeholder={CheckObjectNumber(target, ItemProperty.DisplayOrder)}
                    onChange={(e) => this.SetModalValue(ItemProperty.DisplayOrder, e.target.value)}
                />
            </div>);

            //Active.
            const active_checked = CheckObjectBoolean(target, ItemProperty.Active);
            components.push(<div key='category-Active' className="form-group">
                <label>Active</label>
                {/* <div className="form-check setting-checkbox" onChange={() => this.SetModalValue(ItemProperty.Groups, !active_checked)}> */}
                <input className="form-check form-check-input" type="checkbox" id={`checkbox-Active`}
                    readOnly={true}
                    checked={active_checked}
                    onClick={() => this.SetModalValue(ItemProperty.Active, !active_checked)}
                />
                {/* </div> */}
            </div>);
        }
        return (components);
    }
    SetModalValue = (propertyName = ItemProperty.None, value = null) => {
        let target = this.state.TargetModal;
        switch (propertyName) {
            case ItemProperty.Name: target[propertyName] = String(value); break;
            case ItemProperty.Description: target[propertyName] = String(value); break;
            case ItemProperty.DisplayOrder: target[propertyName] = CheckNumber(value); break;
            case ItemProperty.Active: target[propertyName] = CheckBoolean(value); break;
            default: break;
        }
        this.setState({
            TargetModal: target,
        });
        if (this.state.isDevMode)
            console.log(`SetModalValue (${propertyName}) (${String(value)}) = \n ${JSON.stringify(target)}`);
    }
    ResetModalValue = () => {
        this.setState({
            TargetModal: JSON.parse(JSON.stringify(this.state.TargetModal_Cached)),
        });
    }
    CRUD_Modal_ViaApi = async () => {

        const editModalState = this.state.EditModalState;
        let statusText = '';
        let urlParam = '';
        switch (editModalState) {
            case CommonState.New: statusText = 'creating'; urlParam = 'Create'; break;
            case CommonState.Edit: statusText = 'updating'; urlParam = 'Update'; break;
            case CommonState.Delete: statusText = 'removing'; urlParam = 'Remove'; break;
            default: break;
        }
        useAppService.getState().setModal('', statusText + ' category...', null, AlertMode.Loading);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = `${GlobalSetting.ApiUrl}Api/LearningCentre/Organizer/Category/${urlParam}`;
        if (this.state.isDevMode)
            console.log('CRUD_Modal_ViaApi (url) \n' + url);

        let targetModal = this.PopulateCategoryItem(this.state.TargetModal);
        targetModal['OrganizerId'] = organizerId;
        targetModal['AuthorId'] = authorId;
        const targetModal_json = JSON.stringify(targetModal);
        if (this.state.isDevMode)
            console.log('CRUD_Modal_ViaApi (json) \n' + targetModal_json);

        let done = false;
        let errorMessage = '';
        let success = false;
        let responseModal = null;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: targetModal_json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log(`api-category-${statusText} (response) \n ${JSON.stringify(data)}`);

                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    if (data.data !== undefined && data.data !== null)
                        responseModal = data.data;
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                }
                done = true;
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message');
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', `api-category-${statusText} (error)\n ${errorMessage}`);
            });
        await DelayUntil(() => done === true);

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditModal(-1, true);
            await Delay(200);
            useAppService.getState().setModal('Success', `Category has been ${urlParam.toLowerCase()}d.`);
            if (editModalState !== CommonState.Delete && responseModal !== null) {
                //re-open edit modal.
                const findIndex_modal = this.state.List.findIndex(x => x.Id === CheckObjectNumber(responseModal, ItemProperty.Id));
                if (findIndex_modal > -1)
                    this.ToggleEditModal(findIndex_modal);
            }
        }
        else {
            useAppService.getState().setModal(`Failed to ${urlParam}`,
                `Category has failed to ${urlParam.toLowerCase()}. ${CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage}`);
        }
    }
    ToggleDeleteTargetModal = () => {
        this.setState({
            DeleteTargetModal_Toggle: !this.state.DeleteTargetModal_Toggle,
        });
    }
    RemoveTargetModal = async () => {
        const index = CheckNumber(this.state.TargetModalIndex, -1);
        if (index < 0)
            return null;
        this.setState({
            EditModalState: CommonState.Delete,
        });
        await Delay(0);
        await this.CRUD_Modal_ViaApi();
        this.ToggleDeleteTargetModal();
    }
    //#endregion === New / Update ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left"><h5>Organizer Category</h5></td>
                        <td className="center"></td>
                        <td className="right">
                            {/* <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ ShowSearchRoomByRoomCodeModal: true, SearchRoomByRoomCode_Processing: false, SearchRoomByRoomCode_RoomCode: '' })}
                            >Search Room</Button> */}
                            <Button
                                variant='outline-primary'
                                onClick={() => this.ToggleEditModal()}
                            >New Category</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left">Name</th>
                        <th className="left">Description</th>
                        <th width='115'>Display Order</th>
                        <th width='95'>Active</th>
                        <th width='95'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Category - Edit / Create - Modal */}
            <Modal show={this.state.EditUiModal_Toggle}
                onHide={() => this.ToggleEditModal(-1, true)}
                centered
                dialogClassName='alert-dialog-bordered'
            // size='sm'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>{this.state.EditModalState} Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.EditModalComponents()}
                    {CommonStatusMessage(this.state.EditModalState, this.state.CommonStatus)}
                </Modal.Body>
                {
                    this.state.EditModalState === CommonState.Processing ? null :
                        <Modal.Footer>
                            {
                                CheckObjectNumber(this.state.TargetModal, 'Id') > 0 ?
                                    <Button variant="danger" className="pull-left" onClick={() => this.ToggleDeleteTargetModal()}>Remove</Button>
                                    : null
                            }
                            <Button variant="secondary" onClick={() => this.ResetModalValue()}>Reset</Button>
                            <Button variant="primary" onClick={() => this.CRUD_Modal_ViaApi()}>{CheckObjectNumber(this.state.TargetModal, 'Id') > 0 ? 'Update' : 'Create'}</Button>
                            <Button variant="secondary" onClick={() => this.ToggleEditModal(-1, true)}>Close</Button>
                        </Modal.Footer>
                }
            </Modal>

            {/* Category - Delete - Modal */}
            <Modal show={this.state.DeleteTargetModal_Toggle} onHide={() => this.ToggleDeleteTargetModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Name: <b>{CheckObjectStringEmpty(this.state.TargetModal, ItemProperty.Name)}</b>
                        <br />Description: <b>{CheckObjectStringEmpty(this.state.TargetModal, ItemProperty.Description)}</b>
                        <br />Are you sure you want to <b>delete</b> current category ?
                        <br />The deletion is not reversible.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteTargetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.RemoveTargetModal()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}